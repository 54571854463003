import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "@mui/material";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px;
  max-width: 1500px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
  margin-bottom: 8px;
`;

const StyledColorTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;
const StyledTitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

const StyledAddonValue = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledEndDateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 8px;

  width: 100%;

  border: 1px solid #ff9ac9;
  border-radius: 12px;
  margin-top: 40px;
`;

const StyledIcon = styled(Icons)``;

const StyledFeatureIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
  margin-bottom: 16px;
`;

const StyledFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: #fff5fa;
  border-radius: 8px;
  height: 156px;
`;

const StyledIncludedItemsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;

  width: 100%;

  border: 1px solid #ebe6e9;
  border-radius: 12px;
  margin-top: 56px;
`;

const StyledServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledSignatureTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledIncludedText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.success[40]};
`;

const StyledValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledSignatureValue = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export {
  StyledContainer,
  StyledTitle,
  StyledTitleText,
  StyledAddonValue,
  StyledColorTitle,
  StyledSubtitle,
  StyledEndDateBox,
  StyledIcon,
  StyledFeatureIcon,
  StyledFeatureContainer,
  StyledIncludedItemsBox,
  StyledServicesContainer,
  StyledSignatureTitle,
  StyledIncludedText,
  StyledValueContainer,
  StyledSignatureValue,
};

import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  ColumnDiv,
  Container,
  DateDiv,
  DateInfoText,
  DateText,
  TextDiv,
} from "./styled";

export const BilletData = ({
  isSelection = false,
}: {
  isSelection?: boolean;
}) => {
  return (
    <Container isSelection={isSelection}>
      {!isSelection && (
        <ShapeIcon
          variant="default"
          name="BarcodeMethod"
          size={48}
          color="#F20D7A"
        />
      )}
      <TextDiv>
        <Typography variant="headline8" weight={700}>
          Dados do boleto
        </Typography>
        {isSelection && (
          <DateInfoText variant="body3">
            O boleto será enviado por e-mail na data de cobrança.
          </DateInfoText>
        )}
        <DateDiv>
          <ColumnDiv>
            <DateInfoText variant="body4" weight={700}>
              Data de vencimento
            </DateInfoText>
            <DateText variant="body3" weight={600}>
              Dia 20 de cada mês
            </DateText>
          </ColumnDiv>
        </DateDiv>
      </TextDiv>
    </Container>
  );
};

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: ${(props) => (props.selected ? "#FFF5FA" : "#FFF")};
  border: 1px solid ${(props) => (props.selected ? "#FF9AC9" : "#EBE6E9")};
  gap: 16px;
  padding: 32px;
  flex: 1 0 0;
`;

export const FirstDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
export const SecondDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const Subtitle = styled(Typography)`
  color: #83727d;
`;

import { Radio, ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, FirstDiv, SecondDiv, Subtitle } from "./styled";

interface IPaymentSelectionCard {
  paymentMethod: string;
  checked: boolean;
  onCheck: () => void;
}

export const PaymentSelectionCard = ({
  paymentMethod,
  checked,
  onCheck,
}: IPaymentSelectionCard) => {
  return (
    <Container selected={checked}>
      <FirstDiv>
        <ShapeIcon
          variant="default"
          name={paymentMethod == "billet" ? "BarcodeMethod" : "IconCreditCard"}
          size={48}
          color="#F20D7A"
        />
        <Radio checked={checked} onChange={onCheck} />
      </FirstDiv>
      <SecondDiv>
        <Typography variant="headline9" weight={700}>
          {paymentMethod == "billet" ? "Boleto bancário" : "Cartão de crédito"}
        </Typography>
        <Subtitle variant="body4" weight={400} color="#6B5B66">
          {paymentMethod == "billet"
            ? " Pode ser pago através de uma conta ou agência bancária e tem prazo de compensação de até 3 dias úteis."
            : "A cobrança será efetuada automaticamente na data estipulada para pagamento."}
        </Subtitle>
      </SecondDiv>
    </Container>
  );
};

import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { CreditCardForm } from "../CreditCardForm";
import Grid from "@mui/material/Grid";
import { Subtitle, SwitchMethodDiv } from "./styled";
import { BilletData } from "../BilletData";
import { Dispatch, SetStateAction } from "react";

interface IPaymentInformation {
  paymentMethod: string;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
  handleSubmitCreditCard: ({
    name,
    documentNumber,
    cardNumber,
    dueDate,
    cvv,
  }: {
    name: string;
    documentNumber: string;
    cardNumber: string;
    dueDate: string;
    cvv: string;
  }) => void;
  creditCardFormRef: any;
}

export const PaymentInformation = ({
  paymentMethod,
  setPaymentMethod,
  handleSubmitCreditCard,
  creditCardFormRef,
}: IPaymentInformation) => {
  return (
    <Grid item md={12} sx={{ padding: "40px 0px 0px 0px", width: "97%" }}>
      <Typography
        variant="headline7"
        weight={700}
        style={{ marginBottom: "8px" }}
      >
        Informações de pagamento
      </Typography>
      <Subtitle variant="body3" style={{ marginBottom: "24px" }}>
        {paymentMethod == "billet" ? (
          <>
            <b>O boleto será enviado por e-mail</b> e pode ser pago através de
            uma conta ou agência bancária com prazo de compensação de até 3 dias
            úteis. Você pode alterar o método de pagamento na área de{" "}
            <b>Pagamento e Cobranças.</b>
          </>
        ) : (
          <>
            A cobrança será efetuada no <b>cartão de crédito</b> automaticamente
            na data estipulada para pagamento.
          </>
        )}
      </Subtitle>

      {paymentMethod == "billet" ? (
        <BilletData />
      ) : (
        <CreditCardForm
          onSubmit={handleSubmitCreditCard}
          ref={creditCardFormRef}
        />
      )}

      <SwitchMethodDiv>
        <Typography variant="body3">
          {paymentMethod == "billet"
            ? "Não quer mais pagar com boleto?"
            : "Não pode usar o cartão de crédito?"}
        </Typography>
        <LinkButton
          variant="primary"
          onClick={() => {
            if (paymentMethod == "billet") setPaymentMethod("creditCard");
            else setPaymentMethod("billet");
          }}
        >
          {paymentMethod == "billet"
            ? "Pagar com cartão de crédito"
            : "Pagar com boleto"}{" "}
        </LinkButton>
      </SwitchMethodDiv>
    </Grid>
  );
};

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledSliderTab = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 64px;
  cursor: pointer;
  border-radius: ${(props) => (props.active ? "8px 8px 0px 0px" : "none")};
  background: ${(props) => (props.active ? "#FFF5FA" : "")};
  padding: 16px 0px 16px 24px;
`;

export { StyledTitle, StyledText, StyledSliderTab };

import { Grid } from "@mui/material";
import {
  PriceDiv,
  PricingContainer,
  StyledIcon,
  StyledSubtitle,
  StyledText,
} from "./styled";

export const PricingBox = ({ price }: { price: string | undefined }) => {
  return (
    <Grid md={12} marginBottom={3} marginTop={5}>
      <PricingContainer>
        <StyledIcon
          name="IconRefresh"
          size={24}
          fill="transparent"
          color="#F20D7A"
        />
        <StyledSubtitle variant="body3">Sua assinatura Pessoas</StyledSubtitle>
        <PriceDiv>
          <StyledText variant="headline6" style={{ marginRight: "4px" }}>
            {price}
          </StyledText>
          <StyledText variant="body3">por pessoa/mês</StyledText>
        </PriceDiv>
      </PricingContainer>
    </Grid>
  );
};

import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled.h1`
  color: ${({ theme }) => theme.colors.secondary[10]};
  font-size: 36px;
`;

export { StyledDiv, StyledText };

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DataInput, GridBillingDataForm } from "./styled";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { getCep } from "@flash-tecnologia/hros-web-utility";

export type FormDataHandle = {
  handleSubmit: () => void;
  resetForm: () => void;
};
interface BilletDataFormProps {
  onSubmit: ({
    email,
    street,
    complement,
    zipCode,
    state,
    district,
    city,
    number,
    phone,
  }: {
    email: string;
    street: string;
    complement: string;
    zipCode: string;
    state: string;
    district: string;
    city: string;
    number: string;
    phone: string;
  }) => void;
}

export const BillingDataForm = React.forwardRef<
  FormDataHandle,
  BilletDataFormProps
>(({ onSubmit }, ref) => {
  const [handleInvalidZipCode, setHandleInvalidZipCode] =
    useState<boolean>(true);

  const validationSchema = yup.object({
    email: yup.string().required("Por favor, digite o e-mail"),
    phone: yup
      .string()
      .min(16, "O número deve conter contem 11 digitos")
      .max(16, "O número deve conter contem 11 digitos")
      .required("Por favor, digite o número de celular"),
    zipCode: yup
      .string()
      .min(9, "O CEP deve conter 8 digitos")
      .required("Por favor, digite o CEP da empresa"),
    street: yup.string().required("Por favor, digite o Logradouro da empresa"),
    number: yup.string().required("Por favor, digite o Número"),
    complement: yup.string().optional(),
    district: yup.string().required("Por favor, digite a Cidade da empresa"),
    state: yup.string().required("Por favor, digite o Estado da empresa"),
    city: yup.string().required("Por favor, digite o Cidade da empresa"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      zipCode: "",
      street: "",
      number: "",
      complement: "",
      district: "",
      state: "",
      city: "",
    },
    validationSchema: validationSchema,
    validate: () => {
      const errors: any = {};
      return errors;
    },
    onSubmit: ({
      email,
      street,
      complement,
      zipCode,
      state,
      district,
      city,
      number,
      phone,
    }) => {
      const body = {
        email,
        street,
        complement,
        zipCode,
        state,
        district,
        city,
        number,
        phone,
      };
      onSubmit(body);
    },
  });
  const fields = [
    {
      id: "email",
      name: "email",
      label: "E-mail",
      grid: 12,
      error: formik.touched.email && Boolean(formik.errors.email),
      helperText: formik.touched.email && formik.errors.email,
    },
    {
      id: "phone",
      name: "phone",
      label: "Celular",
      grid: 12,
      error: formik.touched.phone && Boolean(formik.errors.phone),
      helperText: formik.touched.phone && formik.errors.phone,
      mask: "(00) 0 0000-0000",
    },
    {
      id: "zipCode",
      name: "zipCode",
      label: "CEP",
      grid: 12,
      error:
        (formik.touched.zipCode && Boolean(formik.errors.zipCode)) ||
        !handleInvalidZipCode,
      helperText: formik.touched.zipCode && formik.errors.zipCode,
      mask: "00000-000",
    },
    {
      id: "street",
      name: "street",
      label: "Rua",
      grid: 8,
      error: formik.touched.street && Boolean(formik.errors.street),
      helperText: formik.touched.street && formik.errors.street,
    },
    {
      id: "number",
      name: "number",
      label: "Número",
      grid: 4,
      error: formik.touched.number && Boolean(formik.errors.number),
      helperText: formik.touched.number && formik.errors.number,
    },
    {
      id: "complement",
      name: "complement",
      label: "Complemento",
      grid: 12,
      error: formik.touched.complement && Boolean(formik.errors.complement),
      helperText: formik.touched.complement && formik.errors.complement,
    },
    {
      id: "district",
      name: "district",
      label: "Bairro",
      grid: 12,
      error: formik.touched.district && Boolean(formik.errors.district),
      helperText: formik.touched.district && formik.errors.district,
    },
    {
      id: "state",
      name: "state",
      label: "Estado",
      grid: 6,
      error: formik.touched.state && Boolean(formik.errors.state),
      helperText: formik.touched.state && formik.errors.state,
    },
    {
      id: "city",
      name: "city",
      label: "Cidade",
      grid: 6,
      error: formik.touched.city && Boolean(formik.errors.city),
      helperText: formik.touched.city && formik.errors.city,
    },
  ];

  useEffect(() => {
    const parsedZipCode = formik?.values?.zipCode?.replace(/[^\d]+/g, "");

    if (parsedZipCode?.length === 8) {
      (async () => {
        const zipCodeData = await getCep(parsedZipCode);
        setHandleInvalidZipCode(!!zipCodeData.uf);
        formik.setValues({
          email: formik.values.email || "",
          phone: formik.values.phone || "",
          zipCode: formik.values.zipCode || "",
          street: zipCodeData.logradouro || "",
          number: formik.values.number || "",
          complement: formik.values.complement || "",
          district: zipCodeData.bairro || "",
          state: zipCodeData.uf || "",
          city: zipCodeData.localidade || "",
        });
      })();
    }

    formik.setValues({
      email: formik.values.email || "",
      phone: formik.values.phone || "",
      zipCode: formik.values.zipCode || "",
      street: formik.values.street || "",
      number: formik.values.number || "",
      complement: formik.values.complement || "",
      district: formik.values.district || "",
      state: formik.values.state || "",
      city: formik.values.city || "",
    });
  }, [formik.values.zipCode]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => formik.handleSubmit(),
    resetForm: () => formik.resetForm(),
  }));

  return (
    <GridBillingDataForm item xs={12}>
      <Typography
        variant="headline7"
        weight={700}
        style={{ marginBottom: "24px" }}
      >
        Dados de cobrança
      </Typography>
      <Grid container md={12} spacing={3}>
        {fields.map((field) => {
          return (
            <>
              <Grid item md={field.grid}>
                <DataInput
                  id={field.id}
                  name={field.name}
                  label={field.label}
                  value={formik.values[field.id]}
                  error={field.error}
                  onChange={formik.handleChange}
                  helperText={field.helperText}
                  imaskProps={field.mask ? { mask: field.mask } : undefined}
                />
              </Grid>
            </>
          );
        })}
      </Grid>
    </GridBillingDataForm>
  );
});

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid var(--Neutral-Neutral-80, #d1c7ce);
  background: var(--Neutral-Neutral-100, #fff);
  padding: 32px;
  gap: 4px;
`;

export const StyledIcon = styled(Icons)``;

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

export const PriceDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import Grid from "@mui/material/Grid";
import { PaymentDiv, Subtitle } from "./styled";
import { Dispatch, SetStateAction } from "react";
import { PaymentSelectionCard } from "../../../PaymentSelectionCard";
import { CreditCardForm } from "../CreditCardForm";

interface IPaymentSelection {
  paymentMethod: string;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
  handleSubmitCreditCard: ({
    name,
    documentNumber,
    cardNumber,
    dueDate,
    cvv,
  }: {
    name: string;
    documentNumber: string;
    cardNumber: string;
    dueDate: string;
    cvv: string;
  }) => void;
  creditCardFormRef: any;
}

const paymentMethods = ["creditCard", "billet"];

export const PaymentSelection = ({
  paymentMethod,
  setPaymentMethod,
  handleSubmitCreditCard,
  creditCardFormRef,
}: IPaymentSelection) => {
  return (
    <Grid item md={12} sx={{ padding: "40px 0px 0px 0px", width: "97%" }}>
      <Typography
        variant="headline7"
        weight={700}
        style={{ marginBottom: "8px" }}
      >
        Método de pagamento
      </Typography>
      <Subtitle variant="body3" style={{ marginBottom: "24px" }}>
        Selecione entre cartão de crédito ou boleto. Você poderá alterar o
        método de pagamento na área de Pagamento e Cobranças.
      </Subtitle>
      <PaymentDiv>
        {paymentMethods.map((method) => {
          return (
            <>
              <PaymentSelectionCard
                paymentMethod={method}
                checked={method == paymentMethod}
                onCheck={() => {
                  setPaymentMethod(method);
                }}
              />
            </>
          );
        })}
      </PaymentDiv>

      {paymentMethod == "billet" ? (
        <Subtitle variant="body3" weight={400}>
          O boleto será enviado por e-mail na data de cobrança e{" "}
          <b>vence no dia 20 de cada mês.</b>
        </Subtitle>
      ) : (
        <CreditCardForm
          onSubmit={handleSubmitCreditCard}
          ref={creditCardFormRef}
        />
      )}
    </Grid>
  );
};

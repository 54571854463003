import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div<{ isSelection: boolean }>`
  display: flex;
  height: ${(props) => (props.isSelection ? "auto" : "120px")};
  padding: 24px;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid #f0ecef;
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

export const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateInfoText = styled(Typography)`
  color: #83727d;
`;
export const DateText = styled(Typography)`
  color: #6b5b66;
`;

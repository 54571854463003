import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { GridTitle } from "./styled";

export const PageTitle = () => {
  return (
    <GridTitle item xs={12}>
      <Typography variant="headline5" weight={700}>
        Conclua sua assinatura de Pessoas
      </Typography>
      <Typography variant="body3" weight={400} color="#83727D">
        Confira sua assinatura e insira abaixo os dados de pagamento para tornar
        seu RH ainda mais estratégico! ⚡
      </Typography>
    </GridTitle>
  );
};

import styled from "styled-components";
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledFeatureIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
  margin-bottom: 16px;
`;

export const StyledFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: #fff5fa;
  border-radius: 8px;
  height: 156px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

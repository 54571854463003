import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  Skeleton,
} from "@mui/material";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import {
  IconButton,
  Icons,
  LinkButton,
  PageHeader,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  getS3Presigned,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

import { Authentication } from "@flash-hros/auth-helper";
const { getAccessToken } = Authentication;

import { FooterBar } from "../../components/FooterBar";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";

import {
  StyledAccordion,
  StyledBox,
  StyledContainer,
  StyledDiv,
  StyledFeatureContainer,
  StyledFeatureIcon,
  StyledIcon,
  StyledServicesContainer,
  StyledSignatureTitle,
  StyledSignatureValue,
  StyledSubtitle,
  StyledText,
  StyledTitle,
  StyledTitleText,
  StyledValueContainer,
} from "./styled";

import { addOnInfos, additionalServices, paymentMethods } from "../../mocks";
import { ConditionsContract } from "../../components/ConditionsContractModal";

import { SuccessModal } from "../../components/SuccessModal";
import dispatchToast from "../../utils/dispatchToast";
import { segment } from "../../utils";
import { trpc } from "../../api/client";
import { PaymentMethodDescriptionBox } from "../../components/PaymentMethodDescriptionBox";
import { CreditCardForm } from "../../components/CreditCardForm";
import { PaymentMethodBox } from "../../components/PaymentMethodBox";

const ClientsPaymentPage = () => {
  const { selectedCompany } = useSelectedCompany();

  const navigate = useNavigate();
  const { state } = useLocation();
  const cardInfosRef = useRef<any>(null);
  const { from: route } = (state as any) || {};

  const [disableByCheck, setDisableByCheck] = useState(false);
  const [toggleVisibility, setToggleVisibility] = useState(false);
  const [endSlider, setEndSlider] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [value, setValue] = useState<string | undefined>("");
  const [contractId, setContractId] = useState<any>("");
  const [statusContract, setStatusContract] = useState<any>("");
  const [contractFinance, setContractFinance] = useState<any>();
  const [contractPaymentMethod, setContractPaymentMethod] = useState<any>("");
  const [html, setHtml] = useState<any>(undefined);
  const [pdf, setPdf] = useState<any>(
    "https://drive.google.com/file/d/10-PvCh4uy6eMGN9a2eUdDUalwaQF3ESz/preview"
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("Cartão de crédito");

  const { data, isError, isLoading } = trpc.getContractsAndProducts.useQuery(
    {
      businessUnity: "people",
    },
    { refetchOnWindowFocus: false }
  );

  // const {
  //   data: dataV2,
  //   isError: isErrorV2,
  //   isLoading: isLoadingV2,
  // } = trpc.getContractByCompanyV2.useQuery();

  const dataV2 = {
    contract: { id: "", status: "" },
    paymentMethod: "",
    price: "",
    contractTemplate: "",
  };
  const isErrorV2 = false;
  const isLoadingV2 = false;

  const contract = data?.contract;
  const product = data?.product;
  const productFinance = data?.productFinance;

  if (isError) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar os dados. Tente novamente mais tarde",
    });
  }

  if (isErrorV2) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar os dados. Tente novamente mais tarde",
    });
  }

  const updateActiveContract = trpc.updateActiveContract.useMutation({
    onSuccess: async () => {
      await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
      setOpenSuccessModal(true);
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const updateContract = trpc.updateContract.useMutation({
    onSuccess: async () => {
      await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
      setOpenSuccessModal(true);
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const updateContractAndCreateBilletCustomer =
    trpc.updateContractAndCreateBilletCustomer.useMutation({
      onSuccess: async () => {
        await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
        setOpenSuccessModal(true);
      },
      onError: () =>
        dispatchToast({
          content:
            "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
          type: "error",
        }),
    });

  const createProductFinanceAndUpdateContract =
    trpc.createProductFinanceAndUpdateContract.useMutation({
      onSuccess: async () => {
        await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
        setOpenSuccessModal(true);
      },
      onError: () =>
        dispatchToast({
          content:
            "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
          type: "error",
        }),
    });

  const createProductFinanceAndValidateCreditCard =
    trpc.createProductFinanceAndValidateCreditCard.useMutation({
      onSuccess: async () => {
        await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
        setOpenSuccessModal(true);
      },
      onError: () =>
        dispatchToast({
          content:
            "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
          type: "error",
        }),
    });

  const validateCreditCard = trpc.validateCreditCard.useMutation({
    onSuccess: () => {
      updateActiveContract.mutate({
        contractId: contractId,
        body: {
          status: "active",
        },
      });
    },

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const signInContractAndValidateCreditCard =
    trpc.signInContractAndValidateCreditCard.useMutation({
      onSuccess: async () => {
        await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
        setOpenSuccessModal(true);
      },
      onError: () => {
        dispatchToast({
          content:
            "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
          type: "error",
        });
      },
    });

  const signInContractAndCreateBilletCustomer =
    trpc.signInContractAndCreateBilletCustomer.useMutation({
      onSuccess: async () => {
        await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
        setOpenSuccessModal(true);
      },
      onError: () => {
        dispatchToast({
          content:
            "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
          type: "error",
        });
      },
    });

  const loading =
    isLoading ||
    validateCreditCard?.isLoading ||
    updateActiveContract?.isLoading ||
    updateContract?.isLoading ||
    createProductFinanceAndUpdateContract?.isLoading ||
    createProductFinanceAndValidateCreditCard?.isLoading ||
    isLoadingV2;

  const formatPrice = (price: string) => {
    const splittedPrice = price?.split("");
    const number =
      splittedPrice[0] +
      splittedPrice[1] +
      "." +
      splittedPrice[2] +
      splittedPrice[3];
    const formatted = parseFloat(number)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatted;
  };

  const handlePaymentMethodSelect = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleSubmitChangeToBillet = () => {
    createProductFinanceAndUpdateContract.mutate({
      contractId: contractId,
      productFinance: {
        productId: product?._id || "",
        startDate: "2023-01-26 15:05:43.037Z",
        endDate: "2023-12-31 15:05:43.037Z",
        paymentsTypes:
          selectedPaymentMethod == "Cartão de crédito"
            ? "creditCard"
            : "boleto",
        price: productFinance?.price as string,
        priceDescription: "Preço do produto",
      },
      contract: {
        status: "active",
        products: [
          {
            _id: product?._id || "",
            finance: productFinance?._id || "",
            acquisitionDate: new Date(Date.now()).toISOString(),
          },
        ],
        type: "subscriber",
      },
    });
  };

  const handleSubmitBillet = () => {
    updateContractAndCreateBilletCustomer.mutate({
      contractId: contractId,
      body: {
        status: "active",
        products: [
          {
            _id: product?._id || "",
            finance: contractFinance?._id || "",
            acquisitionDate: new Date(Date.now()).toISOString(),
          },
        ],
        type: "subscriber",
      },
    });
  };

  const handleSubmitCreditCard = useCallback(
    async (props: {
      number: string;
      holder_name: string;
      exp_month: string;
      exp_year: string;
      cvv: string;
      options: {
        verify_card: boolean;
      };
      isCompanyAddress: boolean;
      billing_address: {
        line_1: string;
        line_2: string;
        zip_code: string;
        city: string;
        state: string;
        country: string;
      };
      phoneNumber: {
        number: string;
        area_code: string;
      };
      documentNumber: string;
    }) => {
      if (dataV2) {
        signInContractAndValidateCreditCard.mutate({
          contractId: contractId,
          creditCard: props,
        });
      }
      if (statusContract != "active") {
        if (
          selectedPaymentMethod != contractPaymentMethod &&
          selectedPaymentMethod == "Cartão de crédito"
        ) {
          createProductFinanceAndValidateCreditCard.mutate({
            contractId: contractId,
            productFinance: {
              productId: product?._id || "",
              startDate: "2023-01-26 15:05:43.037Z",
              endDate: "2023-12-31 15:05:43.037Z",
              paymentsTypes:
                selectedPaymentMethod == "Cartão de crédito"
                  ? "creditCard"
                  : "boleto",
              price: value as string,
              priceDescription: "Preço do produto",
            },
            contract: {
              status: "active",
              products: [
                {
                  _id: product?._id || "",
                  finance: productFinance?._id || "",
                  acquisitionDate: new Date(Date.now()).toISOString(),
                },
              ],
              type: "subscriber",
            },
            creditCard: props,
          });
        } else {
          await validateCreditCard.mutate({
            body: props,
          });
        }
      } else {
        dispatchToast({
          content: "Voce já efetuou a aquisição do produto.",
          type: "error",
        });
      }
    },
    [
      product,
      productFinance,
      contract,
      contractId,
      selectedPaymentMethod,
      contractPaymentMethod,
      dataV2,
    ]
  );

  const FeatureCard = ({ icon, name, addon, soon }: any) => {
    return (
      <StyledFeatureContainer>
        <StyledFeatureIcon name={icon} fill="transparent" />
        <StyledText
          variant="body3"
          style={{ fontWeight: 600, textAlign: "center" }}
        >
          {name}
        </StyledText>
        {addon && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icons
              name="IconPlus"
              fill="transparent"
              color="#F0AAB4"
              size={14}
            />
            <StyledSubtitle variant="caption" style={{ marginLeft: "6.5px" }}>
              {addon}
            </StyledSubtitle>
          </div>
        )}
        {soon && (
          <Tag
            variant="primary"
            style={{
              alignSelf: "center",
              background: "#FFE0EF",
              cursor: "auto",
              pointerEvents: "none",
            }}
          >
            Em breve!
          </Tag>
        )}
      </StyledFeatureContainer>
    );
  };

  const SwiperButton = ({ direction }: any) => {
    const swiper = useSwiper();
    swiper.on("reachEnd", () => {
      setEndSlider(true);
    });
    swiper.on("reachBeginning", () => {
      setEndSlider(false);
    });

    return direction === "next" ? (
      <IconButton
        onClick={() => {
          segment({
            track:
              "people_acquisition_newclient_signupsales_includedservices_arrow_clicked",
          });
          swiper.slideNext();
        }}
        icon="IconArrowRight"
        variant={"line"}
        size={"small"}
        style={{ marginLeft: "12px" }}
        disabled={endSlider}
      />
    ) : (
      <IconButton
        onClick={() => {
          segment({
            track:
              "people_acquisition_newclient_signupsales_includedservices_arrow_clicked",
          });
          swiper.slidePrev();
        }}
        icon="IconArrowLeft"
        variant={"line"}
        size={"small"}
        disabled={!endSlider}
      />
    );
  };

  useEffect(() => {
    (async () => {
      if (dataV2?.contract?.id) {
        setContractId(dataV2?.contract?.id);
        setStatusContract(dataV2?.contract?.status);

        setPdf(dataV2?.contractTemplate);

        setContractPaymentMethod(
          dataV2?.paymentMethod == "Boleto"
            ? "Boleto bancário"
            : "Cartão de crédito"
        );
        setSelectedPaymentMethod(
          dataV2?.paymentMethod == "Boleto"
            ? "Boleto bancário"
            : "Cartão de crédito"
        );

        setValue(dataV2?.price);
      } else {
        if (contract?.length) {
          const peopleContract = contract?.find((c: any) =>
            c.product.some((p: any) => p.businessUnity == "people")
          );

          if (!peopleContract?._id) {
            dispatchToast({
              content:
                "Não foi possível localizar seu contrato. Tente novamente mais tarde.",
              type: "error",
            });
          }

          setContractId(peopleContract?._id);
          setStatusContract(peopleContract?.status);

          if (productFinance) {
            setContractFinance(productFinance);
            setValue(formatPrice(productFinance?.price));
            setContractPaymentMethod(
              productFinance?.paymentsTypes == "creditCard"
                ? "Cartão de crédito"
                : "Boleto bancário"
            );
            setSelectedPaymentMethod(
              productFinance?.paymentsTypes == "creditCard"
                ? "Cartão de crédito"
                : "Boleto bancário"
            );
          } else {
            setValue("");
          }

          const contractTemplateHtml = peopleContract?.contractTemplate?.find(
            (f: any) => f?.html != "" && f?.active != false
          );

          const contractTemplatePdf = peopleContract?.contractTemplate?.find(
            (f) => f?.url != null && f?.active != false
          );

          if (contractTemplateHtml?.html) {
            setHtml(contractTemplateHtml?.html);
          }

          if (contractTemplatePdf?.url?.path != null) {
            const s3 = await getS3Presigned({
              filename: contractTemplatePdf?.url?.path,
              module: "contract-manager",
            });
            setPdf(s3.url);
          }
        }
      }
    })();
  }, [contract, product, productFinance]);

  return (
    <>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/home`);
                },
                label: "Início",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Confirme sua assinatura",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <StyledContainer>
        <StyledBox>
          <StyledDiv>
            <StyledTitle variant="headline5" style={{ width: "80%" }}>
              Conclua sua assinatura de Flash People
            </StyledTitle>
            <StyledSubtitle variant="body3">
              Configure abaixo sua assinatura e insira seu cartão para tornar
              seu RH ainda mais estratégico! ⚡
            </StyledSubtitle>
            <div style={{ marginTop: "60px" }}>
              <StyledTitle variant="headline8">Soluções inclusas</StyledTitle>
              <StyledSubtitle variant="body3">
                Confira todas as soluções que estarão disponíveis para o RH.
              </StyledSubtitle>

              <div style={{ marginTop: "20px" }}>
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={2.9}
                  slidesPerGroup={2.9}
                  spaceBetween={20}
                >
                  {additionalServices.map((item: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <SwiperSlide>
                          <FeatureCard
                            name={item?.name}
                            icon={item?.icon}
                            addon={item?.addon}
                            soon={item?.soon}
                          />
                        </SwiperSlide>
                      </React.Fragment>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      marginTop: "12px",
                    }}
                  >
                    <SwiperButton direction={"next"} />
                    <SwiperButton direction={"prev"} />
                  </div>
                </Swiper>
              </div>
            </div>
          </StyledDiv>
          <div style={{ padding: "0px 32px 32px 32px" }}>
            <StyledAccordion
              expanded={toggleVisibility}
              sx={{
                boxShadow: "none",
                border: " 1px solid #ff9ac9",
                borderRadius: "12px !important",
              }}
            >
              <AccordionSummary sx={{ cursor: "auto !important" }}>
                <div
                  style={{
                    padding: "24px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <StyledIcon
                    name="IconRefresh"
                    size={24}
                    fill="transparent"
                    color="#F20D7A"
                  />
                  <StyledSubtitle variant="body3">
                    Sua assinatura Flash People
                  </StyledSubtitle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "4px",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton
                        variant="rectangular"
                        width="132px"
                        height="42px"
                      />
                    ) : (
                      <StyledText
                        variant="headline6"
                        style={{ marginRight: "4px" }}
                      >
                        {value}
                      </StyledText>
                    )}
                    <StyledText variant="body3">por pessoa/mês</StyledText>
                  </div>
                  <div
                    style={{
                      marginTop: "28px",
                      display: toggleVisibility ? "none" : "flex",
                      justifyContent: "center",
                    }}
                  >
                    <LinkButton
                      variant="primary"
                      onClick={() => {
                        segment({
                          track:
                            "people_acquisition_newclient_signupsales_subscription_showdetails_clicked",
                        });
                        setToggleVisibility(!toggleVisibility);
                      }}
                    >
                      Mostrar Detalhes{" "}
                      <Icons
                        name="IconChevronDown"
                        fill="transparent"
                        color="#F20D7A"
                      />
                    </LinkButton>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Divider sx={{ borderColor: "#FFE0EF" }} />
                <div style={{ padding: "32px" }}>
                  <StyledTitle variant="headline8">
                    Detalhes da assinatura
                  </StyledTitle>
                  <StyledSubtitle
                    variant="body3"
                    style={{ marginBottom: "56px" }}
                  >
                    O valor total mensal é calculado no final de cada mês,
                    considerando o volume de pessoas que estiveram cadastradas
                    na plataforma durante qualquer período do mês de cálculo.
                  </StyledSubtitle>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <StyledSignatureTitle
                      variant="body3"
                      style={{ fontWeight: 700 }}
                    >
                      Adicionais
                    </StyledSignatureTitle>
                    {addOnInfos.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <StyledServicesContainer>
                            <StyledTitleText variant="body3">
                              {item?.addOn}
                            </StyledTitleText>
                            <StyledSignatureValue variant="body3">
                              + R$ {item.price} pessoa/mês
                            </StyledSignatureValue>
                            {/* <StyledIncludedText variant="body3">
                                    Já incluso
                                  </StyledIncludedText> */}
                          </StyledServicesContainer>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <Divider sx={{ my: "24px" }} />

                  <StyledValueContainer>
                    <div>
                      <StyledSignatureTitle
                        variant="body3"
                        style={{ fontWeight: 700 }}
                      >
                        Valor final da Assinatura
                      </StyledSignatureTitle>
                    </div>
                    <StyledSignatureValue
                      variant="body3"
                      style={{ fontWeight: 700 }}
                    >
                      {value} pessoa/mês
                    </StyledSignatureValue>
                  </StyledValueContainer>
                </div>
                <Divider sx={{ borderColor: "#FFE0EF" }} />
                <div
                  style={{
                    padding: "24px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LinkButton
                    variant="primary"
                    onClick={() => {
                      setToggleVisibility(!toggleVisibility);
                    }}
                  >
                    Ocultar Detalhes{" "}
                    <Icons
                      name="IconChevronUp"
                      fill="transparent"
                      color="#F20D7A"
                    />
                  </LinkButton>
                </div>
              </AccordionDetails>
            </StyledAccordion>
          </div>
        </StyledBox>
        <StyledBox>
          <>
            <StyledTitle variant="headline7" style={{ fontWeight: 700 }}>
              Escolha seu método de pagamento
            </StyledTitle>
            <StyledSubtitle variant="body3" style={{ fontWeight: 400 }}>
              Escolha qual meio de pagamento faz mais sentido para você.
            </StyledSubtitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                margin: "40px 0px",
              }}
            >
              {paymentMethods.map((payment, index) => {
                return (
                  <React.Fragment key={index}>
                    <PaymentMethodBox
                      paymentMethod={payment.paymentMethod}
                      paymentMethodDescription={
                        payment.paymentMethodDescription
                      }
                      onPaymentMethodSelect={() =>
                        handlePaymentMethodSelect(payment.paymentMethod)
                      }
                      iconName={payment.icon}
                      checked={selectedPaymentMethod === payment.paymentMethod}
                    />
                  </React.Fragment>
                );
              })}
            </div>

            {selectedPaymentMethod == "Cartão de crédito" && (
              <CreditCardForm
                ref={cardInfosRef}
                onSubmit={handleSubmitCreditCard}
                setDisableByCheck={setDisableByCheck}
                disableByCheck={disableByCheck}
                setOpenContractModal={setOpenContractModal}
              />
            )}
            {(selectedPaymentMethod == "Boleto bancário" ||
              selectedPaymentMethod == "Pix") && (
              <PaymentMethodDescriptionBox
                paymentMethod={selectedPaymentMethod}
                setDisableByCheck={setDisableByCheck}
                disableByCheck={disableByCheck}
                setOpenContractModal={setOpenContractModal}
              />
            )}
          </>
        </StyledBox>
      </StyledContainer>
      <ConditionsContract
        isPdf={html ? false : true}
        onOpen={openContractModal}
        documentLink={html ? html : pdf}
        setOpenModal={setOpenContractModal}
        setChecked={setDisableByCheck}
        segmentClose={() =>
          segment({
            track: "people_acquisition_newclient_signupsales_close_clicked",
          })
        }
        segmentConfirm={() =>
          segment({
            track:
              "people_acquisition_newclient_signupsales_readandaccept_clicked",
          })
        }
      />
      <SuccessModal
        title={"A sua assinatura da Flash People já está ativa ⚡"}
        onOpen={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        route={route?.pathname ? route?.pathname : "/home"}
      />
      <FooterBar
        loading={loading}
        disabled={!contractId ? false : disableByCheck}
        segmentCancel={() =>
          segment({
            track: "people_acquisition_newclient_signupsales_cancel_clicked",
          })
        }
        nextButtonIcon={"IconCheck"}
        nextHandleClick={() => {
          switch (true) {
            // case dataV2?.contract?.id &&
            //   selectedPaymentMethod == "Cartão de crédito":
            //   cardInfosRef.current.handleSubmit();
            //   break;
            // case dataV2?.contract?.id &&
            //   selectedPaymentMethod == "Boleto bancário":
            //   signInContractAndCreateBilletCustomer.mutate({
            //     contractId,
            //   });
            //   break;

            case selectedPaymentMethod == contractPaymentMethod &&
              selectedPaymentMethod == "Cartão de crédito":
              cardInfosRef.current.handleSubmit();
              break;
            case selectedPaymentMethod == contractPaymentMethod &&
              selectedPaymentMethod == "Boleto bancário":
              handleSubmitBillet();
              break;
            case selectedPaymentMethod != contractPaymentMethod &&
              selectedPaymentMethod == "Cartão de crédito":
              cardInfosRef.current.handleSubmit();
              break;
            case selectedPaymentMethod != contractPaymentMethod &&
              selectedPaymentMethod == "Boleto bancário":
              handleSubmitChangeToBillet();
              break;
          }

          segment({
            track: "people_acquisition_newclient_signupsales_confirm_clicked",
          });
        }}
        previousHandleClick={() => {
          segment({
            track: "people_acquisition_newclient_signupsales_return_clicked",
          });
          navigate("/home");
        }}
      />
    </>
  );
};

export { ClientsPaymentPage };

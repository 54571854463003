import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDiv,
  StyledText,
  StyledTitle,
} from "./styled";

const FaqAccordion = ({
  title,
  text,
  segment,
}: {
  title: string;
  text: string;
  segment: any;
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <StyledAccordion
      onChange={() => setExpanded(!expanded)}
      onClick={() => {
        segment();
      }}
    >
      <StyledAccordionSummary
        expandIcon={
          expanded ? (
            <Icons name="IconX" fill="transparent" color="#F20D7A" />
          ) : (
            <Icons name="IconPlus" fill="transparent" color="#3B2E37" />
          )
        }
      >
        <StyledTitle expanded={expanded} variant="headline8">
          {title}
        </StyledTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <StyledDiv>
          <StyledText variant="body3">{text}</StyledText>
        </StyledDiv>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export { FaqAccordion };

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledFooterBar,
  StyledButtonContainer,
  StyledLinkButton,
  StyledButton,
} from "./styled";

const FooterBar = ({
  nextHandleClick,
  previousHandleClick,
  nextHandleButtonLabel,
  nextButtonIcon,
  previousHandleButtonLabel,
  loading,
  disabled,
  segmentCancel,
}: {
  nextHandleClick: any;
  previousHandleClick: any;
  nextHandleButtonLabel?: any;
  nextButtonIcon?: any;
  previousHandleButtonLabel?: any;
  loading?: any;
  disabled: boolean;
  segmentCancel?: any;
}) => {
  return (
    <StyledFooterBar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StyledLinkButton
          variant="primary"
          style={{ alignSelf: "center" }}
          onClick={() => {
            segmentCancel();
            window.location.href = "/home";
          }}
        >
          Cancelar
        </StyledLinkButton>
        <StyledButtonContainer>
          <StyledButton
            size={"large"}
            variant="secondary"
            onClick={previousHandleClick}
          >
            {previousHandleButtonLabel ? previousHandleButtonLabel : "Voltar"}
          </StyledButton>
          <StyledButton
            loading={loading}
            size={"large"}
            variant="primary"
            style={{
              marginLeft: "24px",
            }}
            onClick={nextHandleClick}
            disabled={!disabled}
          >
            {nextHandleButtonLabel ? nextHandleButtonLabel : "Confirmar"}
            {nextButtonIcon ? (
              <Icons
                name={nextButtonIcon}
                fill="transparent"
                color={!disabled ? "#BBAAB5" : "#FFF"}
              />
            ) : (
              <></>
            )}
          </StyledButton>
        </StyledButtonContainer>
      </div>
    </StyledFooterBar>
  );
};

export { FooterBar };

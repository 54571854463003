import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import { ConsultantContactBox } from "../../components/ConsultantContactBox";
import { ModulesCarousel } from "../../components/ModulesCarousel";
import { FooterBar } from "../../components/FooterBar";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { Breadcrumb } from "../../components/Breadcrumb";

import {
  Button,
  Icons,
  PageHeader,
  Tag,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledBackgroundDiv,
  StyledContainer,
  StyledDiv,
  StyledDiv2,
  StyledFeatureCardContainer,
  StyledHalfDiv,
  StyledModuleCardContainer,
  StyledSpaceBetweenDiv,
  StyledSubtitle,
  StyledText,
  StyledTitle,
  StyledTitleText,
  StyledValueText,
} from "./styled";

import SubscriptionImage from "../../assets/subscription.png";

import {
  cardInfos,
  addOnInfos,
  additionalServices2,
  faqInfos,
} from "../../mocks";
import { FaqAccordion } from "../../components/FaqAccordion";
import { Skeleton } from "@mui/material";
import { segment } from "../../utils";
import { trpc } from "../../api/client";

const FlashPeoplePage = () => {
  const [endSlider, setEndSlider] = useState(false);
  const [contractType, setContractType] = useState("");

  const navigate = useNavigate();

  const simulationDiv = useRef<any>(null);

  const { data, isError, isLoading } = trpc.getContractsAndProducts.useQuery(
    {
      businessUnity: "people",
    },
    { refetchOnWindowFocus: false }
  );

  const product = data?.product;
  const productFinance = data?.productFinance;
  const contract = data?.contract;

  if (isError) {
  }

  const formatPrice = (price: string) => {
    if (price) {
      const splittedPrice = price.split("");
      const number =
        splittedPrice[0] +
        splittedPrice[1] +
        "." +
        splittedPrice[2] +
        splittedPrice[3];
      const formatted = parseFloat(number);
      return formatted;
    } else {
      return "";
    }
  };

  const ModuleCards = ({ icon, title, subtitle }: any) => {
    return (
      <StyledModuleCardContainer>
        <Icons fill="transparent" name={icon} color="#F20D7A" />
        <StyledText style={{ fontWeight: 700 }} variant="body3">
          {title}
        </StyledText>
        <StyledSubtitle variant="caption">{subtitle}</StyledSubtitle>
      </StyledModuleCardContainer>
    );
  };

  const FeatureCard = ({ icon, name, addon, soon }: any) => {
    return (
      <StyledFeatureCardContainer>
        <Icons fill="transparent" name={icon} color="#F20D7A" />
        <StyledText
          variant="body3"
          style={{
            fontWeight: 700,
            textAlign: "center",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          {name}
        </StyledText>
        {addon && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icons
              name="IconPlus"
              fill="transparent"
              color="#F0AAB4"
              size={17}
            />
            <StyledSubtitle variant="caption" style={{ marginLeft: "4px" }}>
              {addon}
            </StyledSubtitle>
          </div>
        )}
        {soon && (
          <Tag
            variant="primary"
            style={{
              alignSelf: "center",
              cursor: "auto",
              pointerEvents: "none",
              background: "#FFE0EF",
            }}
          >
            Em breve!
          </Tag>
        )}
      </StyledFeatureCardContainer>
    );
  };
  const SwiperButton = ({ direction }: any) => {
    const swiper = useSwiper();

    swiper.on("reachEnd", () => {
      setEndSlider(true);
    });
    swiper.on("reachBeginning", () => {
      setEndSlider(false);
    });

    return direction === "next" ? (
      <Icons
        onClick={() => {
          segment({
            track:
              "people_acquisition_newclient_signupsales_benefitsexpenseclient_carousel_arrow_clicked",
          });
          swiper.slideNext();
        }}
        name="IconArrowRight"
        color={endSlider ? "#D1C7CE" : "#352830"}
        fill="transparent"
        style={{ marginLeft: "12px", cursor: endSlider ? "auto" : "pointer" }}
      />
    ) : (
      <Icons
        onClick={() => {
          segment({
            track:
              "people_acquisition_newclient_signupsales_benefitsexpenseclient_carousel_arrow_clicked",
          });
          swiper.slidePrev();
        }}
        name="IconArrowLeft"
        color={!endSlider ? "#D1C7CE" : "#352830"}
        fill="transparent"
        style={{ cursor: !endSlider ? "auto" : "pointer" }}
      />
    );
  };

  useEffect(() => {
    (async () => {
      const peopleContract = contract?.find((c: any) =>
        c.product.some((p: any) => p.businessUnity == "people")
      );

      const legacyContract = contract?.find((c: any) =>
        c.product.some((p: any) => p.businessUnity != "people")
      );

      if (!peopleContract?.status && !legacyContract?.status) {
        setContractType("new");
      }

      if (legacyContract?.status) {
        setContractType("legacy");
      }
    })();
  }, [contract, product, productFinance]);

  return (
    <>
      <PageHeader>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/people/acquisition`);
                },
                label: "Início",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Conheça a Flash People",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <StyledBackgroundDiv>
        <StyledContainer>
          <StyledSpaceBetweenDiv>
            <StyledDiv2>
              <StyledText style={{ fontWeight: 700 }} variant="body2">
                Conheça a solução de Gestão de Pessoas
              </StyledText>
              <Typography variant="headline4" style={{ width: "80%" }}>
                O sistema certo para o RH atingir resultados excepcionais
              </Typography>
              <StyledSubtitle variant="body3" style={{ width: "80%" }}>
                Uma única plataforma com soluções para processos de admissão
                online, treinamentos, desenvolvimento e engajamento dos
                colaboradores. Completa, segura e automatizada para o RH.
              </StyledSubtitle>
              {/* <div style={{ marginTop: "49px", marginBottom: "49px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <StyledValueText
                    variant="headline6"
                    style={{ marginRight: "4px" }}
                  >
                    {isLoading ? (
                      <Skeleton
                        variant="rectangular"
                        width="132px"
                        height="42px"
                      />
                    ) : (
                      formatPrice(
                        productFinance?.price as string
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    )}
                  </StyledValueText>
                  <StyledValueText variant="body3">
                    por pessoa/mês
                  </StyledValueText>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <StyledSubtitle variant="body4">
                    Assinatura mínima de R$ 99,00/mês
                  </StyledSubtitle>
                  <Tooltip
                    title={
                      "Caso o volume de pessoas ativas no mês seja menor que 10, será cobrada uma assinatura mínima de R$ 99,00/mês."
                    }
                    placement="right-start"
                  >
                    <button style={{ cursor: "default", marginLeft: "4px" }}>
                      <Icons
                        name="IconInfoCircle"
                        fill="transparent"
                        color="#83727D"
                        size={16}
                      />
                    </button>
                  </Tooltip>
                </div>
              </div> */}
              <Button
                style={{ marginTop: "49px" }}
                variant="primary"
                size="large"
                onClick={() => {
                  segment({
                    track:
                      "people_acquisition_newclient_signupsales_benefitsexpenseclient_simulatesubscription_button_clicked",
                  });
                  window.open(
                    `https://flashapp.com.br/gestao-de-pessoas?utm_source=Flash-OS&utm_medium=LP&utm_campaign=lp-pessoas-flashos&utm_content=cta`,
                    "_blank"
                  );
                  // simulationDiv.current.scrollIntoView({
                  //   behavior: "smooth",
                  // });
                }}
              >
                Falar com um especialista
                {/* <Icons fill="transparent" name="IconChevronsDown" /> */}
              </Button>
            </StyledDiv2>
            <div>
              <img
                alt=""
                style={{ borderTopLeftRadius: "56px", width: "90%" }}
                src={SubscriptionImage}
              />
            </div>
          </StyledSpaceBetweenDiv>
          <div style={{ marginTop: "120px" }}>
            <Swiper
              modules={[Navigation]}
              slidesPerView={3.2}
              slidesPerGroup={3.2}
              spaceBetween={10}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <StyledTitle
                  variant="headline7"
                  style={{ width: "40%", marginBottom: "32px" }}
                >
                  Impulsione o RH da sua empresa com uma plataforma inteligente
                  e eficiente
                </StyledTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-start",
                    marginTop: "12px",
                  }}
                >
                  <SwiperButton direction={"next"} />
                  <SwiperButton direction={"prev"} />
                </div>
              </div>

              {cardInfos.map((item: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <SwiperSlide>
                      <ModuleCards
                        icon={item?.icon}
                        title={item?.title}
                        subtitle={item?.subtitle}
                      />
                    </SwiperSlide>
                  </React.Fragment>
                );
              })}
            </Swiper>
          </div>
          <div>
            <ModulesCarousel />
          </div>
        </StyledContainer>
      </StyledBackgroundDiv>
      <StyledContainer>
        {/* <StyledDiv>
          <StyledHalfDiv>
            <div style={{ marginBottom: "52px" }} ref={simulationDiv}>
              <StyledValueText
                style={{ marginBottom: "12px" }}
                variant="headline6"
              >
                Sua assinatura
              </StyledValueText>
              <StyledTitleText variant="body3" style={{ width: "80%" }}>
                Ao contratar Flash People você garante todas as nossas soluções
                e uma gestão de pessoas mais automatizada, estratégica e
                eficiente.
              </StyledTitleText>
            </div>
            <div style={{ marginBottom: "16px" }}>
              <div style={{ marginBottom: "32px" }}>
                <StyledValueText
                  style={{ marginBottom: "12px" }}
                  variant="headline8"
                >
                  Soluções inclusas
                </StyledValueText>
                <StyledTitleText variant="body3" style={{ width: "80%" }}>
                  Por período limitado, os adicionais dos módulos de Admissão e
                  Treinamentos já estão inclusos gratuitamente na sua
                  assinatura. Aproveite!
                </StyledTitleText>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                {additionalServices2.map((item: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <FeatureCard
                        name={item?.name}
                        icon={item?.icon}
                        addon={item.addon}
                        soon={item?.soon}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </StyledHalfDiv>
          <StyledHalfDiv>
            <div style={{ marginBottom: "32px" }}>
              <StyledValueText
                style={{ marginBottom: "12px" }}
                variant="headline8"
              >
                Como funciona
              </StyledValueText>
              <StyledTitleText variant="body3" style={{ width: "80%" }}>
                O valor total da sua assinatura é calculado no final de cada
                mês, e considera o volume de pessoas que estiveram cadastradas
                na plataforma durante qualquer período do mês de cálculo.
              </StyledTitleText>
            </div>
            {isLoading ? (
              <Skeleton variant="rectangular" width="535px" height="672px" />
            ) : (
              <SimulationSlider
                totalAddOnPrice={0}
                addOns={addOnInfos}
                subscriptionValue={formatPrice(productFinance?.price as string)}
              />
            )}
          </StyledHalfDiv>
        </StyledDiv> */}
        <div style={{ marginTop: "120px" }}>
          <Typography variant="headline5" style={{ marginBottom: "54px" }}>
            Dúvidas frequentes
          </Typography>
          {faqInfos.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <FaqAccordion
                  title={item?.title}
                  text={item?.text}
                  segment={() => segment({ track: item?.track })}
                />
              </React.Fragment>
            );
          })}
        </div>
        <ConsultantContactBox />
      </StyledContainer>
      <FooterBar
        segmentCancel={() => {
          segment({
            track:
              "people_acquisition_newclient_signupsales_benefitsexpenseclient_footer_cancel_button_clicked",
          });
        }}
        disabled={true}
        nextHandleClick={() => {
          segment({
            track:
              "people_acquisition_newclient_signupsales_footer_subscribenow_button_clicked",
          });
          window.open(
            `https://flashapp.com.br/gestao-de-pessoas?utm_source=Flash-OS&utm_medium=LP&utm_campaign=lp-pessoas-flashos&utm_content=cta`,
            "_blank"
          );

          // window.location.href = "/people/acquisition/new-acquisition/payment";
        }}
        previousHandleClick={() => {
          // if (contractType == "new") {
          //   navigate("/acquisition/trial");
          // }
          if (contractType == "new") {
            navigate("/people/acquisition");
          }
          if (contractType == "legacy") {
            navigate("/people/acquisition");
          }
          if (contractType != "legacy" && contractType != "new") {
            navigate("/people/acquisition");
          }
          segment({
            track:
              "people_acquisition_newclient_signupsales_benefitsexpenseclient_footer_return_button_clicked",
          });
        }}
        nextHandleButtonLabel="Falar com um especialista"
        previousHandleButtonLabel={
          // contractType == "new" ? "Teste grátis por 7 dias" : "Voltar"
          "Voltar"
        }
      />
    </>
  );
};

export { FlashPeoplePage };

import { AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import {
  AccordionContainer,
  DetailsModuleContainer,
  SummaryContainer,
  SummaryModuleContainer,
} from "./styled";
import {
  IconTypes,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { IncludedServiceCard } from "../../../IncludedServiceCard";

interface IIncludedServices {
  isLoading: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  services: string[];
}

const modules: {
  icon: IconTypes;
  service: string;
  description: string;
  id: string;
}[] = [
  {
    icon: "IconHierarchy",
    service: "Organograma",
    description: "Visualize e organize a estrutura hierárquica.",
    id: "orgchart",
  },
  {
    icon: "IconChartPie",
    service: "Analytics",
    description: "Veja dashboards com análises personalizadas.",
    id: "analytics",
  },
  {
    icon: "IconSpeakerphone",
    service: "Engajamento",
    description: "Crie e compartilhe comunicações e pesquisas.",
    id: "engagement",
  },
  {
    icon: "IconSchool",
    service: "Treinamentos",
    description: "Ofereça treinamentos para desenvolver habilidades.",
    id: "lms",
  },
  {
    icon: "IconLayoutKanban",
    service: "Fluxo de pessoas",
    description: "Controle os fluxos de admissão e desligamento.",
    id: "flows",
  },
  {
    icon: "IconRocket",
    service: "Performance",
    description: "Avalie e melhore o desempenho das pessoas.",
    id: "performance",
  },
  {
    icon: "IconClock",
    service: "Controle de jornada",
    description: "Gerencie e acompanhe o tempo de trabalho.",
    id: "time-and-attendance",
  },
];

export const IncludedServices = ({
  isLoading,
  open,
  setOpen,
  services,
}: IIncludedServices) => {
  return (
    <Grid md={12}>
      <AccordionContainer
        expanded={open}
        sx={{
          boxShadow: "none",
          border: "1px solid #D1C7CE",
          borderRadius: "12px !important",
        }}
      >
        <AccordionSummary sx={{ cursor: "auto !important" }}>
          <SummaryContainer>
            <Typography
              variant="headline7"
              weight={700}
              style={{ marginBottom: "16px" }}
            >
              Serviços inclusos
            </Typography>
            <Grid container spacing={3}>
              {services
                ?.filter((_, index) => index <= 1)
                ?.map((service) => {
                  const module = modules.find(
                    (module) => module?.id == service
                  );
                  if (module) {
                    return (
                      <Grid item md={6}>
                        <IncludedServiceCard
                          icon={module.icon}
                          description={module.description}
                          service={module.service}
                        />
                      </Grid>
                    );
                  }
                })}
            </Grid>

            <div
              style={{
                marginTop: "24px",
                display: open ? "none" : "flex",
                justifyContent: "center",
              }}
            >
              <LinkButton
                variant="primary"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Mostrar mais{" "}
                <Icons
                  name="IconChevronDown"
                  fill="transparent"
                  color="#F20D7A"
                />
              </LinkButton>
            </div>
          </SummaryContainer>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {services
              ?.filter((_, index) => index > 1)
              ?.map((service) => {
                const module = modules.find((module) => module?.id == service);
                if (module) {
                  return (
                    <Grid item md={6}>
                      <IncludedServiceCard
                        icon={module.icon}
                        description={module.description}
                        service={module.service}
                      />
                    </Grid>
                  );
                }
              })}
          </Grid>

          <div
            style={{
              marginTop: "24px",
              display: open ? "flex" : "none",
              justifyContent: "center",
            }}
          >
            <LinkButton
              variant="primary"
              onClick={() => {
                setOpen(!open);
              }}
            >
              Mostrar menos{" "}
              <Icons name="IconChevronUp" fill="transparent" color="#F20D7A" />
            </LinkButton>
          </div>
        </AccordionDetails>
      </AccordionContainer>
    </Grid>
  );
};

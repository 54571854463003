import { IconTypes, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, StyledFeatureIcon, Subtitle } from "./styled";

interface IIncludedServiceCard {
  icon: IconTypes;
  service: string;
  description: string;
}
export const IncludedServiceCard = ({
  icon,
  service,
  description,
}: IIncludedServiceCard) => {
  return (
    <Container>
      <StyledFeatureIcon name={icon} size={40} fill="transparent" />
      <Typography variant="headline8" weight={700}>
        {service}
      </Typography>
      <Subtitle variant="body4" weight={400}>
        {description}
      </Subtitle>
    </Container>
  );
};

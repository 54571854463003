import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import React from "react";
import { StyledText, StyledTitle } from "./styled";
import ReactPlayer from "react-player";

const ModulesVideoPlayer = ({ type }: any) => {
  const modulesContent = {
    insights: {
      title: "Insights",
      text: "Alcance resultados transformadores com dados inteligentes sobre os seus colaboradores",
      infos: [
        "Informações e insights baseados em dados",
        "Relatórios atualizados em tempo real",
        "Relatórios de diversidade, funcionários por área e muito mais",
      ],
      url: "https://www.youtube.com/watch?v=-bXGoUUDs-Y&ab_channel=Flash",
    },
    hiring: {
      title: "Admissão Digital",
      text: "Um RH estratégico automatiza seus processos desde a admissão de colaboradores",
      infos: [
        "Gestão de candidatos <b>simples e com automações</b>",
        "Envio e coleta do aceite da <b>carta proposta</b>",
        "Gestão e coleta de <b>assinatura digital</b> de documentos",
        "<b>Acompanhamento de cada etapa</b> de forma simplificada e totalmente digital",
      ],
      url: "https://www.youtube.com/watch?v=-bXGoUUDs-Y&ab_channel=Flash",
    },
    learning: {
      title: "Cursos",
      text: "Quando você desenvolve seus colaboradores, sua empresa atinge níveis extraordinários de performance",
      infos: [
        "Construa e gerencie <b>programas de treinamento e desenvolvimento</b> com facilidade",
        "Crie e disponibilize <b>treinamentos em vídeo, áudio ou PDF</b>",
        "Valide o conhecimento dos alunos com <b>avaliações personalizadas</b>",
        "<b>Acompanhe os resultados</b> de engajamento de cada curso e desempenho dos alunos",
      ],
      url: "https://www.youtube.com/watch?v=T7qa_i2IXF4&ab_channel=Flash",
    },
    engagement: {
      title: "Engajamento",
      text: "Supere suas metas de engajamento e alcance um nível excepcional de conexão com seus colaboradores",
      infos: [
        "Criação e envio de <b>mensagens e pesquisas personalizadas</b> para os colaboradores",
        "Mural de notícias e informações da empresa direto <b>no app Flash</b>",
        "Acompanhamento e <b>monitoramento das interações</b> com suas mensagens",
      ],
      url: "https://www.youtube.com/watch?v=waGd4-HUXr0&ab_channel=Flash",
    },
    orgchart: {
      title: "Organograma",
      text: "O RH se destaca quando a estrutura da organização é visível e acessível para todos os colaboradores",
      infos: [
        "<b>Controle e edição do organograma</b> da empresa de forma simplificada",
        "Visualização clara dos colaboradores <b>por departamento e hierarquia</b>",
        "Atualização em <b>tempo real</b>",
        "Acessível para todos e de <b>fácil compartilhamento</b>",
      ],
      url: "https://www.youtube.com/watch?v=54Pq8vnT9VQ&ab_channel=Flash",
    },
    employeesManagement: {
      title: "Gestão de colaboradores",
      text: "Uma solução de gestão de RH que é a sua cara! Todos os benefícios e uma plataforma prática, intuitiva e segura.",
      infos: [
        "Personalização da sua conta dentro da Flash com seu <b>logo e as cores da sua empresa</b>",
        "Cadastro e <b>gestão de administradores</b> simplificada",
        "<b>Segurança e proteção dos dados</b> dos colaboradores e da organização em todas as etapas",
      ],
      url: "https://www.youtube.com/watch?v=zl6C7k9EI7A&t=1s&ab_channel=Flash",
    },
    peopleAnalytics: {
      title: "People Analytics",
      text: "Alcance resultados transformadores com dados inteligentes sobre os seus colaboradores",
      infos: [
        "Informações e insights baseados em <b>dados reais</b>",
        "Relatórios atualizados em <b>tempo real</b>",
        "<b>Diversos filtros</b> e <b>facilidade para baixar</b> e compartilhar relatórios",
        "Relatórios de <b>diversidade, funcionários por área</b> e muito mais",
      ],
      url: "https://www.youtube.com/watch?v=HPpHwmjGjsQ&ab_channel=Flash",
    },
    ged: {
      title: "Gestão Eletrônica de Documentos",
      text: "Todos os documentos dos colaboradores e de seus dependentes em um só lugar, de maneira fácil e organizada",
      infos: [
        "Acesse e organize  os documentos de cada um dos seus colaboradores",
        "Fácil acesso a versões digitalizadas dos documentos",
        "Mantenha os documentos de dependentes atualizados",
      ],
      url: "https://www.youtube.com/watch?v=eOe9_fGpQ7Q&ab_channel=Flash",
    },
  } as any;

  const DescriptionBox = ({ title, text }: any) => {
    return (
      <div style={{ width: "40%", marginRight: "80px" }}>
        <StyledTitle
          style={{ fontWeight: 700, marginBottom: "12px" }}
          variant="body3"
        >
          {title}
        </StyledTitle>
        <StyledText variant="body2" style={{ fontWeight: 700 }}>
          {text}
        </StyledText>

        <Divider
          sx={{ width: "30%", mt: "20px", border: "1px solid #F20D7A" }}
        />
      </div>
    );
  };

  const InfosList = ({ info }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Icons name="IconCheck" fill="transparent" color="#F0AAB4" />
        <Typography style={{ marginLeft: "14px" }} variant="body4">
          <div dangerouslySetInnerHTML={{ __html: info }} />
        </Typography>
      </div>
    );
  };

  return (
    <div style={{ width: "70%" }}>
      <div style={{ marginBottom: "40px" }}>
        <ReactPlayer
          key={type}
          playing={true}
          muted={true}
          url={modulesContent[type].url}
        />
        {/* <StyledVideoPlayer
          key={type}
          options={{
            autoplay: true,
            fluid: true,
            src: modulesContent[type].url,
          }}
        /> */}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <DescriptionBox
          title={modulesContent[type].title}
          text={modulesContent[type].text}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "40%",
          }}
        >
          {modulesContent[type].infos.map((item: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <InfosList info={item} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { ModulesVideoPlayer };

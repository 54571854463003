import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Input, Slider } from "@mui/material";
import styled from "styled-components";

const StyledTitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

const StyledValueText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledSlider = styled(Slider)`
  height: 8;
  margin-bottom: 12px;
  .MuiSlider-thumb {
    height: 40px;
    width: 40px;
    background-color: #fff;
    border: 2px solid;
  }
  .MuiSlider-rail {
    opacity: 0.5;
    background-color: #ebe6e9;
  }
  .MuiSlider-markLabel {
    font-weight: 400;
    font-size: 8px;

    color: #83727d;
  }
`;

const StyledSliderInput = styled(Input)`
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  width: 60%;
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  input[type="number"] {
    text-align: center;
    cursor: pointer;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;

  border: 1px solid #ebe6e9;
  border-radius: 10px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const StyledServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledSignatureValue = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledIncludedText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.success[40]};
`;

const StyledValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledSignatureTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export {
  StyledTitleText,
  StyledValueText,
  StyledSlider,
  StyledSliderInput,
  StyledContainer,
  StyledServicesContainer,
  StyledSignatureValue,
  StyledIncludedText,
  StyledValueContainer,
  StyledSignatureTitle,
  StyledInfo,
};

import React, { useEffect, useImperativeHandle, useState } from "react";
import { getCep } from "@flash-tecnologia/hros-web-utility";

import * as yup from "yup";
import { useFormik } from "formik";
import { InfoBox } from "../InfoBox";
import { segment } from "../../utils";
import {
  StyledContainer,
  StyledBox2,
  StyledTitle,
  StyledSubtitle,
  StyledErrorIcon,
  FlexDiv,
  StyledInput,
  HelperTextContainer,
  StyledIcon,
  ErrorText,
  StyledToggle,
  StyledSignatureValue,
  StyledTitleText,
  StyledCheckBox,
} from "./styled";
import { Checkbox, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";

export type FormDataHandle = {
  handleSubmit: () => void;
  resetForm: () => void;
};

interface CreditCardFormProps {
  onSubmit: ({
    number,
    holder_name,
    exp_month,
    exp_year,
    cvv,
    options,
    isCompanyAddress,
    billing_address,
    phoneNumber,
    documentNumber,
  }: {
    number: string;
    holder_name: string;
    exp_month: string;
    exp_year: string;
    cvv: string;
    options: {
      verify_card: boolean;
    };
    isCompanyAddress: boolean;
    billing_address: {
      line_1: string;
      line_2: string;
      zip_code: string;
      city: string;
      state: string;
      country: string;
    };
    phoneNumber: {
      number: string;
      area_code: string;
    };
    documentNumber: string;
  }) => void;
  setDisableByCheck;
  disableByCheck;
  setOpenContractModal;
}

export const CreditCardForm = React.forwardRef<
  FormDataHandle,
  CreditCardFormProps
>(
  (
    { onSubmit, setDisableByCheck, disableByCheck, setOpenContractModal },
    ref
  ) => {
    const [showAdress, setshowAdress] = useState<boolean>(false);
    const [hasNumber, setHasNumber] = useState<boolean>(false);
    const [handleInvalidZipCode, setHandleInvalidZipCode] =
      useState<boolean>(true);

    const validationSchema = yup.object({
      showAdress: yup.boolean(),
      ownerName: yup
        .string()
        .required("Confira o nome e sobrenome no cartão")
        .max(64, "O número máximo de caracteres é 64"),
      cardNumber: yup
        .string()
        .min(13, "O Número do cartão deve conter no mínimo 13 dígitos")
        .max(19, "O Número do cartão deve conter no máximo 19 dígitos")
        .required("Confira o número do cartão"),
      expirationDate: yup
        .string()
        .required("Confira a data de vencimento")
        .min(5, "A Data de vencimento deve conter 4 dígitos"),
      cvv: yup
        .string()
        .min(3, "O CVV deve conter no mínimo 3 dígitos")
        .max(4, "O CVV deve conter no máximo 4 dígitos")
        .required("Confira o CVV do cartão"),
      zipCode: yup.string().when("showAddress", {
        is: true,
        then: (field) =>
          field
            .min(9, "O CEP deve conter 8 digitos")
            .required("Por favor, digite o CEP da empresa"),
        otherwise: (field) => field.optional(),
      }),

      street: yup.string().when("showAddress", {
        is: true,
        then: (field) =>
          field.required("Por favor, digite o Logradouro da empresa"),
        otherwise: (field) => field.optional(),
      }),
      district: yup.string().when("showAddress", {
        is: true,
        then: (field) =>
          field.required("Por favor, digite a Cidade da empresa"),
        otherwise: (field) => field.optional(),
      }),
      state: yup.string().when("showAddress", {
        is: true,
        then: (field) =>
          field.required("Por favor, digite o Estado da empresa"),
        otherwise: (field) => field.optional(),
      }),
      city: yup.string().when("showAddress", {
        is: true,
        then: (field) =>
          field.required("Por favor, digite o Cidade da empresa"),
        otherwise: (field) => field.optional(),
      }),
      phone: yup
        .string()
        .min(16, "O número deve conter contem 11 digitos")
        .max(16, "O número deve conter contem 11 digitos")
        .required("Por favor, digite o número de celular"),
      documentNumber: yup
        .string()
        .min(14, "O número deve conter contem 11 digitos")
        .max(14, "O número deve conter contem 11 digitos")
        .required("Por favor, digite o número do CPF"),
    });

    const formik = useFormik({
      initialValues: {
        ownerName: "",
        cardNumber: "",
        expirationDate: "",
        cvv: "",
        zipCode: "",
        street: "",
        number: "",
        complement: "",
        district: "",
        state: "",
        city: "",
        phone: "",
        documentNumber: "",
        showAdress: showAdress,
      },
      validationSchema: validationSchema,
      validate: () => {
        const errors: any = {};
        return errors;
      },
      onSubmit: ({
        ownerName,
        cardNumber,
        expirationDate,
        cvv,
        street,
        complement,
        zipCode,
        state,
        district,
        city,
        number,
        phone,
        documentNumber,
      }) => {
        const date = expirationDate.split("/");
        const formatedDate = date[0] + "/" + "20" + date[1];
        expirationDate = formatedDate;

        const body = {
          number: cardNumber,
          holder_name: ownerName,
          exp_month: date[0],
          exp_year: date[1],
          cvv: cvv,
          options: {
            verify_card: true,
          },
          isCompanyAddress: !showAdress,
          billing_address: {
            line_1: `${number}, ${street}, ${district}`,
            line_2: complement,
            zip_code: zipCode.replace(/[^\d]+/g, ""),
            city: city,
            state: state,
            country: "BR",
          },
          phoneNumber: {
            number: phone.split(/[()]/)[2].replace(/[^\d]+/g, ""),
            area_code: phone.split(/[()]/)[1],
          },
          documentNumber: documentNumber.replace(/[^\d]+/g, ""),
        };
        onSubmit(body);
      },
    });

    useImperativeHandle(ref, () => ({
      handleSubmit: () => formik.handleSubmit(),
      resetForm: () => formik.resetForm(),
    }));

    useEffect(() => {
      const parsedZipCode = formik?.values?.zipCode?.replace(/[^\d]+/g, "");

      if (parsedZipCode?.length === 8) {
        (async () => {
          const zipCodeData = await getCep(parsedZipCode);
          setHandleInvalidZipCode(!!zipCodeData.uf);
          formik.setValues({
            ownerName: formik.values.ownerName || "",
            cardNumber: formik.values.cardNumber || "",
            expirationDate: formik.values.expirationDate || "",
            cvv: formik.values.cvv || "",
            zipCode: formik.values.zipCode || "",
            street: zipCodeData.logradouro || "",
            number: formik.values.number || "",
            complement: formik.values.complement || "",
            district: zipCodeData.bairro || "",
            state: zipCodeData.uf || "",
            city: zipCodeData.localidade || "",
            phone: formik.values.phone || "",
            documentNumber: formik.values.documentNumber || "",
            showAdress: formik.values.showAdress || false,
          });
        })();
      }

      formik.setValues({
        ownerName: formik.values.ownerName || "",
        cardNumber: formik.values.cardNumber || "",
        expirationDate: formik.values.expirationDate || "",
        cvv: formik.values.cvv || "",
        zipCode: formik.values.zipCode || "",
        street: formik.values.street || "",
        number: formik.values.number || "",
        complement: formik.values.complement || "",
        district: formik.values.district || "",
        state: formik.values.state || "",
        city: formik.values.city || "",
        phone: formik.values.phone || "",
        documentNumber: formik.values.documentNumber || "",
        showAdress: formik.values.showAdress,
      });
    }, [formik.values.zipCode]);

    useEffect(() => {
      if (!showAdress) {
        formik.setFieldValue("zipCode", "");
        formik.setFieldValue("street", "");
        formik.setFieldValue("number", "");
        formik.setFieldValue("complement", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("district", "");
      }
    }, [showAdress]);

    return (
      <>
        <StyledContainer>
          <StyledBox2>
            <div>
              <div>
                <div style={{ marginBottom: "32px" }}>
                  <StyledTitle variant="headline8">
                    Cartão de crédito
                  </StyledTitle>
                  <StyledSubtitle variant="body3">
                    A fatura será enviada por{" "}
                    <span style={{ fontWeight: 700 }}>e-mail</span> na data da
                    cobrança.
                  </StyledSubtitle>
                </div>
                <TextField
                  id={"ownerName"}
                  name={"ownerName"}
                  label={"Nome do Titular"}
                  value={formik.values.ownerName}
                  onClick={() => {
                    segment({
                      track:
                        "people_acquisition_newclient_signupsales_cardholdername_filled",
                    });
                  }}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "ownerName",
                      e.target.value.replace(/[^a-zA-Z à-úÀ-Ú]/gi, "")
                    )
                  }
                  error={
                    formik.touched.ownerName && Boolean(formik.errors.ownerName)
                  }
                  helperText={
                    formik.touched.ownerName && formik.errors.ownerName
                  }
                  style={{ width: "100%" }}
                />

                <TextField
                  id={"cardNumber"}
                  name={"cardNumber"}
                  label={"Número do Cartão"}
                  imaskProps={{ mask: "0000 0000 0000 0000 000" }}
                  value={formik.values.cardNumber}
                  onClick={() => {
                    segment({
                      track:
                        "people_acquisition_newclient_signupsales_cardnumber_filled",
                    });
                  }}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "cardNumber",
                      e.target.value.replace(/\s/g, "")
                    )
                  }
                  error={
                    formik.touched.cardNumber &&
                    Boolean(formik.errors.cardNumber)
                  }
                  helperText={
                    formik.touched.cardNumber && formik.errors.cardNumber
                  }
                  style={{ width: "100%", marginTop: "32px" }}
                />

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <TextField
                      id={"expirationDate"}
                      name={"expirationDate"}
                      label={"Data de Vencimento"}
                      imaskProps={{ mask: "00/00" }}
                      value={formik.values.expirationDate}
                      onClick={() => {
                        segment({
                          track:
                            "people_acquisition_newclient_signupsales_expirationdate_filled",
                        });
                      }}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.expirationDate &&
                        Boolean(formik.errors.expirationDate)
                      }
                      style={{
                        width: "100%",
                        marginRight: "10%",
                        marginTop: "32px",
                      }}
                    />
                    {formik.touched.expirationDate &&
                      formik.errors.expirationDate && (
                        <div
                          style={{
                            display: "flex",
                            margin: "6px 0 0 16px",
                          }}
                        >
                          <StyledErrorIcon
                            name="IconInfoCircle"
                            fill="transparent"
                            size={18}
                          />
                          <StyledSubtitle variant="caption">
                            {formik.errors.expirationDate}
                          </StyledSubtitle>
                        </div>
                      )}
                  </div>
                  <div style={{ width: "45%" }}>
                    <TextField
                      id={"cvv"}
                      name={"cvv"}
                      label={"CVV"}
                      imaskProps={{ mask: "0000" }}
                      value={formik.values.cvv}
                      onClick={() => {
                        segment({
                          track:
                            "people_acquisition_newclient_signupsales_verificationcode_filled",
                        });
                      }}
                      onChange={formik.handleChange}
                      error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                      style={{ width: "100%", marginTop: "32px" }}
                    />

                    {formik.touched.cvv && formik.errors.cvv && (
                      <div
                        style={{
                          display: "flex",
                          margin: "6px 0 0 16px",
                        }}
                      >
                        <StyledErrorIcon
                          name="IconInfoCircle"
                          fill="transparent"
                          size={18}
                        />
                        <StyledSubtitle variant="caption">
                          {formik.errors.cvv}
                        </StyledSubtitle>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Divider sx={{ mt: "40px" }} />
                  <FlexDiv>
                    <div style={{ marginTop: "32px" }}>
                      <StyledSubtitle
                        variant="body3"
                        style={{ fontWeight: 700 }}
                      >
                        Informe os dados do titular do cartão
                      </StyledSubtitle>
                    </div>

                    <StyledInput
                      label="CPF"
                      id={"documentNumber"}
                      name={"documentNumber"}
                      value={formik.values.documentNumber}
                      onChange={formik.handleChange}
                      imaskProps={{ mask: "000.000.000-00" }}
                      error={
                        formik.touched.documentNumber &&
                        Boolean(formik.errors.documentNumber)
                      }
                    />
                    {formik?.touched?.documentNumber &&
                      formik?.errors?.documentNumber && (
                        <HelperTextContainer>
                          <StyledIcon
                            size={15}
                            name="IconAlertCircle"
                            fill="none"
                          />
                          <ErrorText variant="body4">
                            {formik.errors.documentNumber}
                          </ErrorText>
                        </HelperTextContainer>
                      )}

                    <StyledInput
                      label="Celular"
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      imaskProps={{ mask: "(00) 0 0000-0000" }}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <HelperTextContainer>
                        <StyledIcon
                          size={15}
                          name="IconAlertCircle"
                          fill="none"
                        />
                        <ErrorText variant="body4">
                          {formik.errors.phone}
                        </ErrorText>
                      </HelperTextContainer>
                    )}
                  </FlexDiv>
                  <FlexDiv>
                    <Divider
                      sx={{ borderColor: "#EBE6E9", mb: "24px", mt: "40px" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <StyledCheckBox
                        checked={disableByCheck}
                        onChange={(e) => setDisableByCheck(e.target.checked)}
                      />
                      <StyledSubtitle
                        variant="body3"
                        style={{ cursor: "pointer", fontWeight: 600 }}
                        onClick={() => {
                          segment({
                            track:
                              "people_acquisition_newclient_signupsales_termsandconditions_clicked",
                          });
                          setOpenContractModal(true);
                        }}
                      >
                        Li e aceito as{" "}
                        <span
                          style={{
                            color: "#F20D7A",
                            textDecoration: "underline",
                            fontWeight: 600,
                          }}
                        >
                          condições comerciais do contrato
                        </span>{" "}
                        da Flash People
                      </StyledSubtitle>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <StyledToggle
                        size="medium"
                        onChange={() => {
                          setshowAdress(!showAdress);
                          formik.handleChange({
                            target: { id: "showAddress", value: !showAdress },
                          });
                          formik.setFieldValue("showAddress", !showAdress);
                        }}
                        checked={!showAdress}
                      />
                      <StyledSignatureValue
                        variant="body3"
                        style={{ fontWeight: 600, marginLeft: "14px" }}
                      >
                        O endereço de cobrança é igual ao endereço da empresa
                      </StyledSignatureValue>
                    </div>
                    {showAdress && (
                      <FlexDiv>
                        <div style={{ marginTop: "32px" }}>
                          <StyledSubtitle variant="body3">
                            Informe o endereço de cobrança
                          </StyledSubtitle>
                        </div>
                        <StyledInput
                          id={"zipCode"}
                          name={"zipCode"}
                          label="CEP"
                          value={formik.values.zipCode}
                          onChange={(e: any) => {
                            formik.handleChange({
                              target: { id: "zipCode", value: e.target.value },
                            });
                          }}
                          error={
                            (formik.touched.zipCode &&
                              Boolean(formik.errors.zipCode)) ||
                            !handleInvalidZipCode
                          }
                          imaskProps={{
                            mask: "00000-000",
                          }}
                        />
                        {formik.touched.zipCode && formik.errors.zipCode && (
                          <HelperTextContainer>
                            <StyledIcon
                              size={15}
                              name="IconAlertCircle"
                              fill="none"
                            />
                            <ErrorText variant="body4">
                              {formik.errors.zipCode}
                            </ErrorText>
                          </HelperTextContainer>
                        )}
                        {!handleInvalidZipCode && (
                          <HelperTextContainer>
                            <StyledIcon
                              size={15}
                              name="IconAlertCircle"
                              fill="none"
                            />
                            <ErrorText variant="body4">
                              Por favor, digite um CEP válido.
                            </ErrorText>
                          </HelperTextContainer>
                        )}

                        <StyledInput
                          id={"street"}
                          name={"street"}
                          label="Logradouro"
                          value={formik.values.street}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.street &&
                            Boolean(formik.errors.street)
                          }
                        />
                        {formik.touched.street && formik.errors.street && (
                          <HelperTextContainer>
                            <StyledIcon
                              size={15}
                              name="IconAlertCircle"
                              fill="none"
                            />
                            <ErrorText variant="body4">
                              {formik.errors.street}
                            </ErrorText>
                          </HelperTextContainer>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "24px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ width: "50%" }}>
                            <StyledInput
                              id={"number"}
                              name={"number"}
                              label="Número"
                              value={formik.values.number}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.number &&
                                Boolean(formik.errors.number)
                              }
                              disabled={hasNumber}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "50%",
                              marginTop: "24px",
                            }}
                          >
                            <Checkbox
                              checked={hasNumber}
                              onChange={(e) => {
                                setHasNumber(e.target.checked);
                                formik.setFieldValue("number", "");
                              }}
                            />

                            <StyledTitleText
                              variant="body3"
                              style={{ fontWeight: 700 }}
                            >
                              Endereço Sem número
                            </StyledTitleText>
                          </div>
                          {formik.touched.number && formik.errors.number && (
                            <HelperTextContainer>
                              <StyledIcon
                                size={15}
                                name="IconAlertCircle"
                                fill="none"
                              />
                              <ErrorText variant="body4">
                                {formik.errors.number}
                              </ErrorText>
                            </HelperTextContainer>
                          )}
                        </div>

                        <StyledInput
                          id={"complement"}
                          name={"complement"}
                          label="Complemento (Opcional)"
                          value={formik.values.complement}
                          onChange={formik.handleChange}
                        />

                        <StyledInput
                          id={"district"}
                          name={"district"}
                          label="Bairro"
                          value={formik.values.district}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.district &&
                            Boolean(formik.errors.district)
                          }
                        />
                        {formik.touched.district && formik.errors.district && (
                          <HelperTextContainer>
                            <StyledIcon
                              size={15}
                              name="IconAlertCircle"
                              fill="none"
                            />
                            <ErrorText variant="body4">
                              {formik.errors.district}
                            </ErrorText>
                          </HelperTextContainer>
                        )}

                        <StyledInput
                          id={"state"}
                          name={"state"}
                          label="Estado"
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.state && Boolean(formik.errors.state)
                          }
                        />
                        {formik.touched.state && formik.errors.state && (
                          <HelperTextContainer>
                            <StyledIcon
                              size={15}
                              name="IconAlertCircle"
                              fill="none"
                            />
                            <ErrorText variant="body4">
                              {formik.errors.state}
                            </ErrorText>
                          </HelperTextContainer>
                        )}

                        <StyledInput
                          id={"city"}
                          name={"city"}
                          label="Cidade"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                        />
                        {formik.touched.city && formik.errors.city && (
                          <HelperTextContainer>
                            <StyledIcon
                              size={15}
                              name="IconAlertCircle"
                              fill="none"
                            />
                            <ErrorText variant="body4">
                              {formik.errors.city}
                            </ErrorText>
                          </HelperTextContainer>
                        )}
                      </FlexDiv>
                    )}
                    <div style={{ marginTop: "24px" }}>
                      <InfoBox />
                    </div>
                  </FlexDiv>
                </div>
              </div>
            </div>
          </StyledBox2>
        </StyledContainer>
      </>
    );
  }
);

import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, Subtitle } from "./styled";
import { Dispatch, SetStateAction } from "react";

export const TermsAndConditionsCheckbox = ({
  checked,
  setChecked,
  setOpenConditionsModal,
}: {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  setOpenConditionsModal: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Container>
      <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
      <Subtitle
        variant="body3"
        style={{ cursor: "pointer", fontWeight: 600 }}
        onClick={() => {
          setOpenConditionsModal(true);
        }}
      >
        Li e aceito as{" "}
        <span
          style={{
            color: "#F20D7A",
            textDecoration: "underline",
            fontWeight: 600,
          }}
        >
          condições comerciais do contrato
        </span>{" "}
        da Flash People
      </Subtitle>
    </Container>
  );
};

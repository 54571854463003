import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Subtitle = styled(Typography)`
  color: #83727d;
`;

export const SwitchMethodDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 24px;
`;

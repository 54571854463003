import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledBox, StyledSubtitle, StyledText, StyledTitle } from "./styled";
import ConsultantImage from "../../assets/consultant.png";

const ConsultantContactBox = () => {
  return (
    <StyledBox>
      <div>
        <img alt="Imagem de um consultor" src={ConsultantImage} />
      </div>
      <div
        style={{ padding: "32px", display: "flex", flexDirection: "column" }}
      >
        <div>
          <StyledTitle variant="headline8">Fale com um consultor</StyledTitle>
          <StyledSubtitle
            style={{ width: "80%", marginTop: "10px", marginBottom: "10px" }}
            variant="body3"
          >
            Entre em contato com a nossa equipe comercial para tirar dúvidas ou
            negociar condições especiais. Nosso time está sempre pronto para te
            atender!
          </StyledSubtitle>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "20px",
            }}
          >
            <Icons name="IconHeadset" fill="transparent" color="#F20D7A" />
            <StyledText
              style={{ fontWeight: 700, marginLeft: "6px" }}
              variant="body3"
            >
              (11) 4118-0488
            </StyledText>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Icons name="IconMail" fill="transparent" color="#F20D7A" />
            <StyledText
              style={{ fontWeight: 700, marginLeft: "6px" }}
              variant="body3"
            >
              comercialpeople@flashapp.com.br
            </StyledText>
          </div>
        </div>
      </div>
    </StyledBox>
  );
};

export { ConsultantContactBox };

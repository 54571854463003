import { segmentEventTracking } from "@flash-tecnologia/hros-web-utility";

type ObjectLiteral = {
  [key: string]: string;
};

const segment = ({
  track,
  params,
  identify,
}: {
  track: string;
  params?: ObjectLiteral;
  identify?: {
    id: string;
    params?: ObjectLiteral;
  };
}) => {
  segmentEventTracking({
    name: track,
    module: "acquisition",
    businessUnit: "people",
    params,
  });
};

export { segment };

import { LinkButton, Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledFooterBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.pure};

  padding: 16px 24px;
  z-index: 1;
  position: sticky;
  bottom: 0px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLinkButton = styled(LinkButton)``;

const StyledButton = styled(Button)`
  align-self: center;
  width: 250px;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export {
  StyledFooterBar,
  StyledButtonContainer,
  StyledLinkButton,
  StyledButton,
};

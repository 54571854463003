import { Grid } from "@mui/material";
import { DataInput } from "./styled";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { FormDataHandle } from "../BillingDataForm";

interface CreditCardFormProps {
  onSubmit: ({
    name,
    dueDate,
    cvv,
    cardNumber,
    documentNumber,
  }: {
    name: string;
    dueDate: string;
    cvv: string;
    cardNumber: string;
    documentNumber: string;
  }) => void;
}

export const CreditCardForm = React.forwardRef<
  FormDataHandle,
  CreditCardFormProps
>(({ onSubmit }, ref) => {
  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Confira o nome e sobrenome no cartão")
      .max(64, "O número máximo de caracteres é 64"),
    cardNumber: yup
      .string()
      .min(13, "O Número do cartão deve conter no mínimo 13 dígitos")
      .max(19, "O Número do cartão deve conter no máximo 19 dígitos")
      .required("Confira o número do cartão"),
    dueDate: yup
      .string()
      .required("Confira a data de vencimento")
      .min(5, "A Data de vencimento deve conter 4 dígitos"),
    cvv: yup
      .string()
      .min(3, "O CVV deve conter no mínimo 3 dígitos")
      .max(4, "O CVV deve conter no máximo 4 dígitos")
      .required("Confira o CVV do cartão"),
    documentNumber: yup
      .string()
      .min(14, "O número deve conter contem 11 digitos")
      .max(14, "O número deve conter contem 11 digitos")
      .required("Por favor, digite o número do CPF"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      documentNumber: "",
      cardNumber: "",
      dueDate: "",
      cvv: "",
    },
    validationSchema: validationSchema,
    validate: () => {
      const errors: any = {};
      return errors;
    },
    onSubmit: ({ name, dueDate, cvv, cardNumber, documentNumber }) => {
      const body = {
        name,
        dueDate,
        cvv,
        cardNumber,
        documentNumber,
      };
      onSubmit(body);
    },
  });
  const fields = [
    {
      id: "name",
      name: "name",
      label: "Nome do titular",
      grid: 12,
      error: formik.touched.name && Boolean(formik.errors.name),
      helperText: formik.touched.name && formik.errors.name,
    },
    {
      id: "documentNumber",
      name: "documentNumber",
      label: "CPF",
      grid: 12,
      error:
        formik.touched.documentNumber && Boolean(formik.errors.documentNumber),
      helperText: formik.touched.documentNumber && formik.errors.documentNumber,
      mask: "000.000.000-00",
    },
    {
      id: "cardNumber",
      name: "cardNumber",
      label: "Número do Cartão",
      grid: 12,
      error: formik.touched.cardNumber && Boolean(formik.errors.cardNumber),
      helperText: formik.touched.cardNumber && formik.errors.cardNumber,
      mask: "0000 0000 0000 0000 000",
    },
    {
      id: "dueDate",
      name: "dueDate",
      label: "Data de vencimento",
      grid: 8,
      error: formik.touched.dueDate && Boolean(formik.errors.dueDate),
      helperText: formik.touched.dueDate && formik.errors.dueDate,
      mask: "00/00",
    },
    {
      id: "cvv",
      name: "cvv",
      label: "CVV",
      grid: 4,
      error: formik.touched.cvv && Boolean(formik.errors.cvv),
      helperText: formik.touched.cvv && formik.errors.cvv,
      mask: "0000",
    },
  ];

  useImperativeHandle(ref, () => ({
    handleSubmit: () => formik.handleSubmit(),
    resetForm: () => formik.resetForm(),
  }));

  return (
    <Grid container md={12} spacing={3}>
      {fields.map((field) => {
        return (
          <>
            <Grid item md={field.grid}>
              <DataInput
                id={field.id}
                name={field.name}
                label={field.label}
                value={formik.values[field.id]}
                error={field.error}
                onChange={formik.handleChange}
                helperText={field.helperText}
                imaskProps={field.mask ? { mask: field.mask } : undefined}
              />
            </Grid>
          </>
        );
      })}
    </Grid>
  );
});

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import styled from "styled-components";

export const GridContainer = styled(Grid)`
  padding: 40px 24px;
`;

export const StyledPageHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledBreadcrumbSeparator = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral.dark.dark5};
  fill: transparent;
`;

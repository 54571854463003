import { segment } from "../../utils";
import { InfoBox } from "../../components/InfoBox";
import {
  Container,
  FlexColumnDiv,
  FlexDiv,
  FlexRowDiv,
  StyledPaymentMethodDescriptionText,
  StyledPaymentMethodInfos,
  StyledPaymentMethodTitle,
  StyledCheckBox,
} from "./styled";

export const PaymentMethodDescriptionBox = ({
  paymentMethod,
  setDisableByCheck,
  disableByCheck,
  setOpenContractModal,
}: {
  paymentMethod;
  setDisableByCheck;
  disableByCheck;
  setOpenContractModal;
}) => {
  let infos = {
    paymentMethod: "",
    paymentMethodDesciption: "",
  };

  switch (paymentMethod) {
    case "Boleto bancário":
      infos = {
        paymentMethod: "Boleto Bancário",
        // paymentMethodDesciption:
        //   "O boleto será enviado para o e-mail <b>flamingo@flash.com.br</b> na data de cobrança.",
        paymentMethodDesciption:
          "O boleto será enviado por e-mail na data de cobrança.",
      };
      break;
    case "Pix":
      infos = {
        paymentMethod: "Pix",
        paymentMethodDesciption:
          "O QR Code será enviado para o e-mail flamingo@flash.com.br na data de cobrança. Lembre-se de realizar o pagamento antes do código expirar.",
      };
      break;
  }
  return (
    <>
      <Container>
        <FlexDiv>
          <StyledPaymentMethodTitle
            variant="headline8"
            style={{ fontWeight: 700 }}
          >
            {infos.paymentMethod}
          </StyledPaymentMethodTitle>
          <StyledPaymentMethodDescriptionText variant="body3">
            <div
              dangerouslySetInnerHTML={{
                __html: infos.paymentMethodDesciption,
              }}
            />
          </StyledPaymentMethodDescriptionText>
        </FlexDiv>

        <FlexRowDiv>
          <FlexColumnDiv>
            <StyledPaymentMethodDescriptionText
              variant="body4"
              style={{ fontWeight: 700 }}
            >
              Data de emissão
            </StyledPaymentMethodDescriptionText>
            <StyledPaymentMethodInfos
              variant="body3"
              style={{ fontWeight: 600 }}
            >
              01 de todo mês
            </StyledPaymentMethodInfos>
          </FlexColumnDiv>
          <FlexColumnDiv>
            <StyledPaymentMethodDescriptionText
              variant="body4"
              style={{ fontWeight: 700 }}
            >
              Vencimento do{" "}
              {paymentMethod == "Boleto bancário" ? "boleto" : "código pix"}
            </StyledPaymentMethodDescriptionText>
            <StyledPaymentMethodInfos
              variant="body3"
              style={{ fontWeight: 600 }}
            >
              05 de todo mês
            </StyledPaymentMethodInfos>
          </FlexColumnDiv>
        </FlexRowDiv>
        <InfoBox />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          <StyledCheckBox
            checked={disableByCheck}
            onChange={(e) => setDisableByCheck(e.target.checked)}
          />
          <StyledPaymentMethodDescriptionText
            variant="body3"
            style={{ cursor: "pointer", fontWeight: 600 }}
            onClick={() => {
              segment({
                track:
                  "peole_acquisition_newclient_signupsales_termsandconditions_clicked",
              });
              setOpenContractModal(true);
            }}
          >
            Li e aceito as{" "}
            <span
              style={{
                color: "#F20D7A",
                textDecoration: "underline",
                fontWeight: 600,
              }}
            >
              condições comerciais do contrato
            </span>{" "}
            da Flash People
          </StyledPaymentMethodDescriptionText>
        </div>
      </Container>
    </>
  );
};

import { Accordion } from "@mui/material";
import styled from "styled-components";

export const AccordionContainer = styled(Accordion)`
  padding: 32px;
  & {
    .MuiAccordionDetails-root {
      padding: 0px;
    }
    .MuiAccordionSummary-root {
      padding: 0px;
    }
  }
`;

export const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const SummaryModuleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-direction: row;
`;

export const DetailsModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
`;

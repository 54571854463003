import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #8ac1fe;
  gap: 16px;
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.info[40]};
  padding: 12px;
  background: #d4e8ff;
  border-radius: 25px;
`;

export const StyledTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
`;

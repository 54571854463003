import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledSubtitle,
  StyledText,
  StyledIcon,
  StyledContainer,
  StyledIconButton,
} from "./styled";

const SuccessModal = ({
  onOpen,
  setOpenModal,
  route,
  title,
  segmentConfirm,
}: {
  onOpen: any;
  setOpenModal: any;
  route: string;
  title: string;
  segmentConfirm?: any;
}) => {
  const modalHeader = (
    <StyledIconButton
      size="small"
      variant="line"
      icon="IconX"
      onClick={() => {
        setOpenModal(false);
        window.location.href = route;
      }}
    />
  );
  return (
    <StyledModal header={modalHeader} open={onOpen} onClose={() => ({})}>
      <StyledContainer>
        <StyledIcon name="IconCheck" fill="transparent" />
        <StyledText variant="body3" style={{ fontWeight: 700 }}>
          Parabéns!
        </StyledText>
        <Typography
          variant="headline6"
          style={{ marginTop: "8px", marginBottom: "24px" }}
        >
          {title}
        </Typography>
        <StyledSubtitle variant="body3">
          A partir de agora você já pode potencializar e centralizar a gestão de
          pessoas da sua empresa.
        </StyledSubtitle>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              window.location.href = route;
              segmentConfirm && segmentConfirm();
            }}
          >
            Começe a usar
            <Icons name={"IconCheck"} fill="transparent" color={"#FFF"} />
          </Button>
        </div>
      </StyledContainer>
    </StyledModal>
  );
};

export { SuccessModal };

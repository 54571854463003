export const addOnInfos = [
  {
    addOn: "Assinatura Eletrônica em Admissão",
    description:
      "Agilize seus processos coletando a assinatura dos seus documentos pela nossa plataforma.",
    price: 0,
  },
  {
    addOn: "Upload de conteúdo em Treinamentos",
    description:
      "Além de criar treinamentos com links de vídeo do Youtube, envie e armazene seus próprios vídeos dentro da nossa plataforma.",
    price: 0,
  },
];

export const additionalServices = [
  {
    name: "Admissão e Demissão Digital",
    icon: "IconLamp",
    addon: "Assinatura Eletrônica",
  },
  {
    name: "Treinamentos",
    icon: "IconSchool",
    addon: "Upload de conteúdo",
  },
  {
    name: "Engajamento",
    icon: "IconSpeakerphone",
  },
  {
    name: "Organograma",
    icon: "IconHierarchy",
  },
  {
    name: "Gestão personalizada",
    icon: "IconCheckbox",
  },
  { name: "Gestão Eletrônica de Documentos", icon: "IconFolders" },

  { name: "People Analytics", icon: "IconChartPie" },
];

export const cardInfos = [
  {
    icon: "IconArrowsMinimize",
    title: "Uma única plataforma com diversas soluções para o RH",
    subtitle:
      "Integre todos os processos de RH em uma só ferramenta e aumente a produtividade e eficiência do seu time.",
  },
  {
    icon: "IconShieldLock",
    title: "Segurança e confiabilidade em todas as etapas",
    subtitle:
      "Centralize os processos e dados dos colaboradores em um sistema seguro e garanta proteção e confiabilidade de todas as informações da sua empresa.",
  },
  {
    icon: "IconSpeakerphone",
    title: "Engajamento com colaboradores de ponta a ponta",
    subtitle:
      "Supere suas metas de engajamento e alcance um nível excepcional de conexão com seus colaboradores com gestão de comunicação, pesquisa e benefícios no mesmo sistema.",
  },
  {
    icon: "IconChartDots3",
    title: "Geração de dados e insights para um RH mais estratégico",
    subtitle:
      "Impulsione sua empresa com gestão inteligente de pessoas. Todas as informações dos seus colaboradores em um só lugar, mais dados e insights estratégicos para facilitar sua gestão.",
  },
  {
    icon: "IconHierarchy3",
    title: "Automação do processo de gestão de pessoas",
    subtitle:
      "Automatize seus processos desde a admissão e ganhe tempo para ser um RH mais estratégico e produtivo, com foco nas pessoas.",
  },
];

export const additionalServices2 = [
  {
    name: "Admissão e Demissão Digital",
    icon: "IconLamp",
    addon: "Assinatura Eletrônica",
  },
  {
    name: "Treinamentos",
    icon: "IconSchool",
    addon: "Upload de conteúdo",
  },
  {
    name: "Engajamento",
    icon: "IconSpeakerphone",
  },
  {
    name: "Organograma",
    icon: "IconHierarchy",
  },
  { name: "People Analytics", icon: "IconChartPie" },
  {
    name: "Gestão personalizada",
    icon: "IconCheckbox",
  },
  {
    name: "Gestão Eletrônica de Documentos",
    icon: "IconFolders",
  },
];

export const faqInfos = [
  {
    title: "O que é a Flash People?",
    text: "Flash People é um sistema completo e descomplicado para o RH com soluções para admissão online, treinamentos, desenvolvimento, engajamento dos colaboradores e muito mais! É isso mesmo, temos tudo que o RH precisa para realizar uma gestão mais estratégica e eficiente, com soluções orientadas a dados para sua empresa gerar resultados transformadores - em um só lugar.",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_faq_whatisflashpeople_clicked",
  },
  {
    title:
      "Qual a diferença entre Flash People e Flash Benefícios? Posso contratar mais de uma solução?",
    text: "Flash People, é um sistema completo e automatizado para o RH que simplifica a gestão de pessoas em todo ecossistema de atuação do RH, da admissão ao engajamento. Legal, né? Por sua vez, Flash Benefícios é uma solução focada em benefícios flexíveis na qual somos pioneiros no mercado. Em um único cartão o colaborador recebe todos os benefícios, com total respaldo jurídico e segurança. Acreditamos que você precisa ter liberdade de escolha para trabalhar e por isso flexibilizamos nossas soluções para você contratar de acordo com sua necessidade.",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_faq_differencebetweenpeopleandbenefits_clicked",
  },
  {
    title: "Quais são os módulos disponíveis?",
    text: "Atualmente nossa plataforma contém os seguintes módulos: Admissão digital (CLT, PJ, estágio), Treinamento, Engajamento, Organograma, GED (Gestão Eletrônica dos Documentos) e People Analytics está saindo do forno. E não paramos por aqui, nosso time de desenvolvimento já está trabalhando para ampliar as funcionalidades oferecidas que serão disponibilizados nas próximas versões do produto.",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_faq_availablemodules_clicked",
  },
  {
    title: "Como a Flash People pode contribuir para o RH da minha empresa?",
    text: "Na Flash nosso propósito é desburocratizar e simplificar todo o ecossistema do RH e com Flash People não seria diferente! Desenvolvemos toda a nossa plataforma com recursos que facilitam e automatizam as principais atividades do RH, assim sobra mais tempo para você fazer uma Gestão de Pessoas mais eficiente e estratégica. E não para aí, nossa solução é orientada a dados para que o RH seja empoderado a tomar decisões mais assertivas e realizar análises preditivas, é o fim das papeladas e das planilhas ;)",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_faq_flashpeopleperks_clicked",
  },
  {
    title: "Como é feita a cobrança da assinatura Flash People? ",
    text: "Nossa cobrança é bem simples e transparente, sua empresa paga por colaboradores cadastrados e ativos na plataforma, excluindo aqueles que foram desativados em meses anteriores ao vigente. No fim do mês, somamos os colaboradores ativos e multiplicamos o preço por colaborador acordado em contrato. Nosso valor mínimo é de R$ 99,00 por mês e dá direito à gestão de até 10 colaboradores. Se você precisar gerenciar um número maior que 10 colaboradores, você pagará por colaborador.",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_faq_flashpeoplebilling_clicked",
  },
  {
    title: "Quando terão novos recursos disponíveis na plataforma? ",
    text: "Estamos sempre buscando evoluir nossas soluções conforme as necessidades dos nossos clientes. Em breve teremos ainda mais recursos disponíveis na nossa plataforma. Quer saber mais? Entre em contato com nossos especialistas e entenda quais funcionalidades estão sendo desenvolvidas pelo nosso time de tecnologia!",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_faq_flashpeoplenewfeatures_clicked",
  },
  {
    title: "Se eu precisar, posso cancelar meu plano a qualquer momento? ",
    text: "Sim. Como a assinatura do plano é mensal, é possível fazer o cancelamento quando quiser, sem nenhuma multa. É importante lembrar que para evitar ser cobrado em outro ciclo de cobrança, você precisa cancelar seu plano pelo menos 30 dias antes da renovação.",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_flashpeoplecancellation_clicked",
  },
  {
    title: "O que é controle de ponto e jornada da FolhaCerta? ",
    text: "A FolhaCerta é uma plataforma de gestão e controle da jornada de trabalho dos colaboradores fundada em 2016 com o foco em desburocratizar a jornada de controle de ponto garantindo mais tempo, praticidade e eficiência para o RH. Atualmente a plataforma possui soluções para controle de horas extras, ausências, escalas, banco de horas, férias e muito mais! A plataforma já foi listada no Ranking 100 Open Startups Brasil 2017 e em 2019, conquistou o prêmio 100 Open Startups. Confira mais detalhes em www.flashapp.com.br/folhacerta. ",
    track:
      "people_acquisition_signupsales_benefitsexpenseclient_folhacerta_clicked",
  },
];

export const paymentMethods = [
  {
    paymentMethod: "Cartão de crédito",
    paymentMethodDescription:
      "Com o cartão de crédito a cobrança será feita automaticamente na data de cobrança.",
    icon: "IconCreditCard",
  },
  // {
  //   paymentMethod: "Pix",
  //   paymentMethodDescription:"Com o Pix você tem pagamento instantâneo que pode ser feito em poucos segundos. É rápido e seguro.",
  //   icon:"IconPix"
  // },
  {
    paymentMethod: "Boleto bancário",
    paymentMethodDescription:
      "Pode ser pago através de uma conta ou agência bancária e tem prazo de compensação de até 3 dias úteis.",
    icon: "IconBarcode",
  },
];

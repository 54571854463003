import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #ebe6e9;
  flex: 1 0 0;
  background: #fff;
  height: 176px;
`;

export const StyledFeatureIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 50px;
  overflow: unset;
`;

export const Subtitle = styled(Typography)`
  color: #53464f;
`;

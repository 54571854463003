import {
  Icons,
  Typography,
  TextField,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Accordion, Container } from "@mui/material";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
  margin-bottom: 8px;
`;

const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px;
  max-width: 1500px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledDiv = styled.div`
  padding: 0px 32px 44px;
`;

const StyledCreditCardDiv = styled.div<{ position: boolean }>`
  position: relative;
  top: 34%;
  transform: ${(props) =>
    props.position ? "translateY(-83%)" : "translateY(-53%)"};
  padding: 32px 32px 44px;

  @media (max-width: 768px) {
    transform: none;
  }
`;

const StyledAddressDiv = styled.div<{ position: boolean }>`
  position: absolute;
  top: 65%;
  display: flex;
  flex-direction: column;
  width: 89%;
`;

const StyledBox = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const StyledBox2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column-reverse;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledIcon = styled(Icons)`
  margin-bottom: 8px;
`;

const StyledSignatureBox = styled.div`
  border: 1px solid #ff9ac9;
  border-radius: 12px;
  padding: 32px;
  margin-top: 40px;
`;

const StyledDetails = styled.div`
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  padding: 32px;
`;

const StyledServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledSignatureTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledSignatureValue = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledFeatureIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
  margin-bottom: 16px;
`;

const StyledFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: #fff5fa;
  border-radius: 8px;
  height: 156px;
`;

const StyledErrorIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error.pure};
`;

const StyledAddOnBox = styled.div<{ checked: boolean }>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px 16px 32px 24px;
  gap: 10px;

  width: 100%;
  height: 152px;

  background: ${(props) => (props.checked ? "#fff5fa" : "")};

  border: 1px solid ${(props) => (props.checked ? "#ff9ac9" : "#EBE6E9")};
  border-radius: 8px;
  margin-bottom: 16px;
`;

const StyledTitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

const StyledAccordion = styled(Accordion)`
  & {
    .MuiAccordionDetails-root {
      padding: 0px;
    }
    .MuiAccordion-root {
      padding: 32px 32px 32px 44px;
    }
  }
`;

const StyledIncludedText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.success[40]};
`;

const StyledInput = styled(TextField)`
  && {
    margin-top: 24px;
    width: 100%;
  }
`;

const HelperTextContainer = styled.div`
  display: flex;
  gap: 5px;

  align-items: center;
  padding-left: 18px;
`;

const ErrorText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
    font-size: 12px;
    font-weight: 700;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledToggle = styled(Toggle)``;

export {
  StyledTitle,
  StyledSubtitle,
  StyledContainer,
  StyledDiv,
  StyledCreditCardDiv,
  StyledBox,
  StyledText,
  StyledIcon,
  StyledSignatureBox,
  StyledDetails,
  StyledServicesContainer,
  StyledValueContainer,
  StyledSignatureTitle,
  StyledSignatureValue,
  StyledInfo,
  StyledFeatureIcon,
  StyledFeatureContainer,
  StyledErrorIcon,
  StyledAddOnBox,
  StyledTitleText,
  StyledAccordion,
  StyledIncludedText,
  StyledInput,
  HelperTextContainer,
  ErrorText,
  StyledAddressDiv,
  FlexDiv,
  StyledBox2,
  StyledToggle,
};

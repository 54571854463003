import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styled from "styled-components";

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;

    &::before {
      background-color: transparent;
    }
  }
  .MuiButtonBase-root {
    padding: 0px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)``;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0px;
  }
`;

const StyledTitle = styled(Typography)<{ expanded: boolean }>`
  color: ${(props) => (props.expanded ? "#F20D7A" : "#3B2E37")};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledDiv = styled.div``;

export {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledTitle,
  StyledText,
  StyledDiv,
};

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div<{ checked: boolean }>`
  display: flex;
  padding: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => (props.checked ? "#ff9ac9" : "#EBE6E9")};
  background-color: ${(props) => (props.checked ? "#FFF5FA" : "#FFF")};
  gap: 16px;
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
`;

export const StyledTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledPaymentMethodText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;
export const StyledPaymentMethodDescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import styled from "styled-components";

export const GridBillingDataForm = styled(Grid)`
  margin: 40px 0px 24px 0px;
  width: 97%;
`;

export const DataInput = styled(TextField)`
  width: 100%;
`;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  TrialPage,
  InitialPage,
  ErrorPage,
  NewClientPaymentPage,
  FlashPeoplePage,
  ClientsPaymentPage,
  CheckoutPage,
  CheckoutPageV2,
} from "../pages";
// import { OpenRoutesGuard } from "./openRoutesGuard";
import { PermissionsRoutesGuard } from "./permissionsRoutesGuard";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PermissionsRoutesGuard />}>
          <Route path="/people/acquisition" element={<InitialPage />} />
          {/* <Route
            path="/people/acquisition/new-acquisition/payment"
            element={<NewClientPaymentPage />}
          /> */}
          <Route
            path="/people/acquisition/simulation"
            element={<FlashPeoplePage />}
          />
          <Route
            path="/people/acquisition/client-acquisition/payment"
            element={<ClientsPaymentPage />}
          />
          <Route
            path="/people/acquisition/checkout"
            // element={<CheckoutPage />}
            element={<CheckoutPageV2 />}
          />
          <Route path="/people/acquisition/trial" element={<TrialPage />} />
          <Route path="/people/acquisition/error" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

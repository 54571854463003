import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const Subtitle = styled(Typography)`
  color: #83727d;
`;

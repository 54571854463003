import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import { Divider, Grid, Skeleton } from "@mui/material";
import {
  Checkbox,
  IconButton,
  Icons,
  Tag,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Contract } from "../../../../server/src/utils/types";

import {
  getS3Presigned,
  getFromLS,
  // getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

import { Authentication } from "@flash-hros/auth-helper";
const { getAccessToken } = Authentication;
// const accessToken = useAuth.getState().accessToken;
// const accessTokenFromLs = getFromLS("hrosAccessToken");
// let companyId = "";
// if (accessTokenFromLs?.company) {
//   const { id } = accessTokenFromLs?.company;
//   companyId = id;
// }

import {
  StyledAddonValue,
  StyledColorTitle,
  StyledContainer,
  StyledEndDateBox,
  StyledFeatureContainer,
  StyledFeatureIcon,
  StyledIcon,
  StyledIncludedItemsBox,
  StyledServicesContainer,
  StyledSignatureTitle,
  StyledSignatureValue,
  StyledSubtitle,
  StyledTitle,
  StyledTitleText,
  StyledValueContainer,
} from "./styled";

import { addOnInfos, additionalServices } from "../../mocks";
import TrialImage from "../../assets/trial.png";
import { FooterBar } from "../../components/FooterBar";

import { ConditionsContract } from "../../components/ConditionsContractModal";
import { SuccessModal } from "../../components/SuccessModal";
import dispatchToast from "../../utils/dispatchToast";
import { segment } from "../../utils";
import { trpc } from "../../api/client";

const TrialPage = () => {
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { from: route } = (state as any) || {};

  const [disableByCheck, setDisableByCheck] = useState(false);
  const [disableByTrial, setDisableByTrial] = useState(false);
  const [endSlider, setEndSlider] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [value, setValue] = useState("");
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("pt-BR")
  );
  const [endTrialDate, setEndTrialDate] = useState(new Date().toISOString());
  const [trialPdfContract, setTrialPdfContract] = useState(null);
  const [waitingTrialContract, setWaitingTrialContract] = useState<Contract>();

  const refToTop = useRef<any>(null);

  useEffect(() => {
    refToTop?.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const { data, isError, isLoading } = trpc.getContractsAndProducts.useQuery({
    businessUnity: "people",
  });

  const product = data?.product;
  const productFinance = data?.productFinance;
  const contract = data?.contract;

  if (isError) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar os dados do colaborador.",
    });
  }

  const updateActiveContract = trpc.updateActiveContract.useMutation({
    onSuccess: async () => {
      await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
      setOpenSuccessModal(true);
    },
    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const createContract = trpc.createContract.useMutation({
    onSuccess: async () => {
      await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
      setOpenSuccessModal(true);
      dispatchToast({
        content: "Sucesso!",
        type: "success",
      });
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro",
        type: "error",
      }),
  });

  const loading =
    isLoading || updateActiveContract?.isLoading || createContract?.isLoading;

  const formatPrice = (price: string) => {
    const splittedPrice = price.split("");
    const number =
      splittedPrice[0] +
      splittedPrice[1] +
      "." +
      splittedPrice[2] +
      splittedPrice[3];
    const formatted = parseFloat(number).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formatted;
  };

  const handleSubmit = async () => {
    if (waitingTrialContract?._id) {
      updateActiveContract.mutate({
        contractId: waitingTrialContract?._id,
        body: {
          status: "trial",
        },
      });
    } else {
      createContract.mutate({
        body: {
          status: "trial",
          products: [
            {
              _id: product?._id || "",
              finance: productFinance?._id || "",
              acquisitionDate: new Date(Date.now()).toISOString(),
            },
          ],
          newClient: true,
          endTrialDate: endTrialDate,
        },
      });
    }
  };

  const EndDateBox = ({ date }: { date: any }) => {
    return (
      <StyledEndDateBox>
        <StyledIcon
          name="IconCalendarEvent"
          size={24}
          fill="transparent"
          color="#F20D7A"
        />
        <StyledSubtitle variant="body3">
          Começando hoje você poderá utilizar até dia
        </StyledSubtitle>
        <StyledColorTitle variant="headline7">{date}</StyledColorTitle>
      </StyledEndDateBox>
    );
  };

  const IncludedItems = ({
    value,
    loading,
  }: {
    value: string;
    loading: any;
  }) => {
    return (
      <StyledIncludedItemsBox>
        <div>
          <StyledTitle variant="headline8">
            Gostou da plataforma? Contrate a qualquer momento do seu teste com
            as condições abaixo!
          </StyledTitle>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <StyledSignatureTitle
              variant="body3"
              style={{ fontWeight: 700, marginTop: "32px" }}
            >
              Adicionais
            </StyledSignatureTitle>
            {addOnInfos.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <StyledServicesContainer>
                    <StyledTitleText variant="body3">
                      {item?.addOn}
                    </StyledTitleText>
                    <StyledAddonValue variant="body3">
                      + R$ {item.price} pessoa/mês
                    </StyledAddonValue>
                    {/* <StyledIncludedText variant="body3">
                      Já incluso
                    </StyledIncludedText> */}
                  </StyledServicesContainer>
                </React.Fragment>
              );
            })}
          </div>
          <Divider sx={{ my: "24px" }} />

          <StyledValueContainer>
            <div>
              <StyledSignatureTitle variant="body3" style={{ fontWeight: 700 }}>
                Valor final da Assinatura
              </StyledSignatureTitle>
              <div style={{ display: "flex" }}>
                <StyledSubtitle variant="body4">
                  Valor mensal mínimo de R$ 99,00/mês
                </StyledSubtitle>
                <div style={{ marginLeft: "6px" }}>
                  <Tooltip
                    title={
                      "Caso o volume de pessoas ativas no mês seja menor que 10, será cobrada uma assinatura mínima de R$ 99,00/mês."
                    }
                    placement="right-start"
                  >
                    <button style={{ cursor: "default" }}>
                      <Icons
                        name="IconInfoCircle"
                        fill="transparent"
                        color="#83727D"
                        size={16}
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
            {loading ? (
              <Skeleton variant="rectangular" width="136px" height="22px" />
            ) : (
              <StyledSignatureValue variant="body3" style={{ fontWeight: 700 }}>
                {value} pessoa/mês
              </StyledSignatureValue>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "11px",
                marginTop: "40px",
              }}
            >
              <Icons
                name="IconInfoCircle"
                fill="transparent"
                color="#83727D"
                size={24}
              />
              <StyledSubtitle variant="body3">
                O valor total mensal é calculado no final de cada mês,
                considerando o volume de pessoas que estiveram cadastradas na
                plataforma durante o período.
              </StyledSubtitle>
            </div>
          </StyledValueContainer>
        </div>
      </StyledIncludedItemsBox>
    );
  };

  const FeatureCard = ({
    icon,
    name,
    addon,
    soon,
  }: {
    icon: any;
    name: string;
    addon: string;
    soon: boolean;
  }) => {
    return (
      <StyledFeatureContainer>
        <StyledFeatureIcon name={icon} fill="transparent" />
        <StyledColorTitle
          variant="body3"
          style={{ fontWeight: 600, textAlign: "center" }}
        >
          {name}
        </StyledColorTitle>
        {addon && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icons
              name="IconPlus"
              fill="transparent"
              color="#F0AAB4"
              size={14}
            />
            <StyledSubtitle variant="caption" style={{ marginLeft: "6.5px" }}>
              {addon}
            </StyledSubtitle>
          </div>
        )}
        {soon && (
          <Tag
            variant="primary"
            style={{
              alignSelf: "center",
              background: "#FFE0EF",
              cursor: "auto",
              pointerEvents: "none",
            }}
          >
            Em breve!
          </Tag>
        )}
      </StyledFeatureContainer>
    );
  };

  const SwiperButton = ({ direction }: { direction: string }) => {
    const swiper = useSwiper();

    swiper.on("reachEnd", () => {
      setEndSlider(true);
    });
    swiper.on("reachBeginning", () => {
      setEndSlider(false);
    });

    return direction === "next" ? (
      <IconButton
        onClick={() => {
          swiper.slideNext();
          segment({
            track: "people_acquisition_newclient_trial_carousel_arrow_clicked",
          });
        }}
        icon="IconArrowRight"
        variant={"line"}
        size={"small"}
        style={{ marginLeft: "12px" }}
        disabled={endSlider}
      />
    ) : (
      <IconButton
        onClick={() => {
          swiper.slidePrev();
          segment({
            track: "people_acquisition_newclient_trial_carousel_arrow_clicked",
          });
        }}
        icon="IconArrowLeft"
        variant={"line"}
        size={"small"}
        disabled={!endSlider}
      />
    );
  };

  useEffect(() => {
    (async () => {
      if (productFinance) {
        setValue(formatPrice(productFinance?.price as any));
      }

      const { url } = await getS3Presigned({
        filename: "contracts/default/termos-de-uso-flashOS-trial.pdf",
        module: "company-management",
      });

      setTrialPdfContract(url);

      if (contract) {
        const hasTrialPeopleContract = contract?.find((c: any) =>
          c.product.some(
            (p: any) => p.businessUnity == "people" && c.endTrialDate
          )
        );

        const hasWaitingTrialPeopleContract = contract?.find((c: any) =>
          c.product.some(
            (p: any) =>
              p.businessUnity == "people" && c.status == "waiting_trial"
          )
        );
        setWaitingTrialContract(
          hasWaitingTrialPeopleContract as unknown as Contract
        );
        const hasSomeContract = contract?.find((c: any) =>
          c.product.some((p: any) => p.businessUnity == "people")
        );
        if (
          hasTrialPeopleContract?._id ||
          (hasSomeContract?._id && !hasWaitingTrialPeopleContract?._id)
        ) {
          setDisableByTrial(true);
          dispatchToast({
            content: "Voce já experimentou o período de testes",
            type: "error",
          });
        }
      } else {
        setDisableByTrial(false);
      }
    })();
    const date = new Date();
    const end = new Date();
    end.setDate(date.getDate() + 7);
    setEndDate(end.toLocaleDateString("pt-BR"));
    setEndTrialDate(end.toISOString());
  }, [product, productFinance, contract]);
  return (
    <>
      <StyledContainer ref={refToTop}>
        <Grid container spacing={3} style={{ padding: "32px 0px 32px 32px" }}>
          <Grid item md={6} xs={12}>
            <StyledTitle variant="headline5" style={{ width: "80%" }}>
              Vamos iniciar seu período de teste por{" "}
              <span style={{ color: "#F20D7A" }}>7 dias!</span>
            </StyledTitle>
            <StyledSubtitle variant="body3">
              Confirme abaixo os detalhes do seu teste gratuito e aceite os
              termos e condições de uso.
            </StyledSubtitle>
            <EndDateBox date={endDate} />
            <div style={{ marginTop: "60px" }}>
              <StyledTitle variant="headline8">
                Confira tudo o que está incluso no seu teste
              </StyledTitle>
              <StyledSubtitle variant="body3">
                Durante este período você poderá experimentar a plataforma
                completa, com todos os adicionais inclusos!
              </StyledSubtitle>

              <div style={{ marginTop: "20px" }}>
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={2.9}
                  slidesPerGroup={2.9}
                  spaceBetween={20}
                >
                  {additionalServices.map((item: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <SwiperSlide>
                          <FeatureCard
                            name={item?.name}
                            icon={item?.icon}
                            addon={item?.addon}
                            soon={item?.soon}
                          />
                        </SwiperSlide>
                      </React.Fragment>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      marginTop: "12px",
                    }}
                  >
                    <SwiperButton direction={"next"} />
                    <SwiperButton direction={"prev"} />
                  </div>
                </Swiper>
              </div>
            </div>
            {/* <IncludedItems value={value} loading={isLoading} /> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "24px",
              }}
            >
              <Checkbox
                checked={disableByCheck}
                onChange={(e) => {
                  setDisableByCheck(e.target.checked);
                  segment({
                    track:
                      "people_acquisition_newclient_trial_termsandconditions_accpeted",
                  });
                }}
              />
              <StyledSubtitle
                variant="body4"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  segment({
                    track:
                      "people_acquisition_newclient_trial_termsandconditions_clicked",
                  });
                  setOpenContractModal(true);
                }}
              >
                Li e aceito as{" "}
                <span
                  style={{
                    color: "#F20D7A",
                    textDecoration: "underline",
                  }}
                >
                  condições do período de teste
                </span>{" "}
                da Flash People
              </StyledSubtitle>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                alt="imagem de uma pessoa segurando um smartphone"
                style={{
                  borderTopRightRadius: "56px",
                  width: "60%",
                  borderBottomLeftRadius: "56px",
                }}
                src={TrialImage}
              />
            </div>
          </Grid>
        </Grid>
      </StyledContainer>
      <FooterBar
        disabled={disableByTrial ? !disableByTrial : disableByCheck}
        loading={loading}
        segmentCancel={() =>
          segment({
            track: "people_acquisition_newclient_trial_cancel_button_clicked",
          })
        }
        nextButtonIcon={"IconCheck"}
        nextHandleClick={() => {
          handleSubmit();
          segment({
            track:
              "people_acquisition_newclient_trial_confirmtest_button_clicked",
          });
        }}
        nextHandleButtonLabel="Confirmar Teste"
        previousHandleClick={() => {
          segment({
            track: "people_acquisition_newclient_trial_back_button_clicked",
          });
          navigate(-1);
        }}
      />
      <SuccessModal
        title={"Seu período de teste da Flash People inicia agora ⚡"}
        onOpen={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        route={route?.pathname ? route?.pathname : "/flows/hiring"}
        segmentConfirm={() => {
          segment({
            track:
              "people_acquisition_newclient_trial_startusing_button_clicked",
          });
        }}
      />
      <ConditionsContract
        isPdf={true}
        onOpen={openContractModal}
        documentLink={
          trialPdfContract != null
            ? trialPdfContract
            : "https://docs.google.com/document/d/1MNN14A_ppx7--3yS50IGAfYVSS-SH8DA/preview"
        }
        setOpenModal={setOpenContractModal}
        setChecked={setDisableByCheck}
        segmentClose={() => {
          segment({
            track:
              "people_acquisition_newclient_trial_termsandconditions_close",
          });
        }}
        segmentConfirm={() => {
          segment({
            track:
              "people_acquisition_newclient_trial_termsandconditions_accpeted",
          });
        }}
      />
    </>
  );
};

export { TrialPage };

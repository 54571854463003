import styled from "styled-components";
import {
  IconButton,
  Icons,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledModal = styled(Modal)``;

const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  margin-bottom: 40px;
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
  margin-bottom: 24px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 450px;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

export {
  StyledModal,
  StyledSubtitle,
  StyledText,
  StyledIcon,
  StyledContainer,
  StyledIconButton,
};

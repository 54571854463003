import { Typography, VideoPlayer } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledVideoPlayer = styled(VideoPlayer)``;

export { StyledTitle, StyledText, StyledVideoPlayer };

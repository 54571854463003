import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "@mui/material";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 130px;
  max-width: 1500px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;
const StyledTitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

const StyledValueText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledFeatureCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  background: #fff5fa;
  border-radius: 8px;
  border: 1px solid #ecdee1;
  width: 90%;
`;

const StyledModuleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 12px;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ecdee1;
  width: 328.5px;
  height: 258px;
`;

const StyledAddOnBox = styled.div<{ checked: boolean }>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 16px 24px 24px;
  gap: 10px;

  width: 491px;
  height: 152px;

  background: ${(props) => (props.checked ? "#fff5fa" : "")};

  border: 1px solid ${(props) => (props.checked ? "#ff9ac9" : "#EBE6E9")};
  border-radius: 8px;
  margin-bottom: 16px;
`;

const StyledBackgroundDiv = styled.div`
  background: linear-gradient(180deg, transparent 50%, #f4e6e9 50%);
`;

const StyledFeatureIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
  margin-bottom: 16px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledHalfDiv = styled.div`
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;

const StyledSpaceBetweenDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 700px) {
    flex-direction: column-reverse;
  }
`;

const StyledDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 58%;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export {
  StyledContainer,
  StyledText,
  StyledSubtitle,
  StyledTitleText,
  StyledValueText,
  StyledTitle,
  StyledFeatureCardContainer,
  StyledModuleCardContainer,
  StyledAddOnBox,
  StyledBackgroundDiv,
  StyledFeatureIcon,
  StyledDiv,
  StyledHalfDiv,
  StyledSpaceBetweenDiv,
  StyledDiv2,
};

import { Grid, LinearProgress } from "@mui/material";
import { StyledSliderTab, StyledText, StyledTitle } from "./styled";
import { ModulesVideoPlayer } from "./ModulesVideoPlayer";
import { Icons, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import React, { useEffect, useState } from "react";
import { segment } from "../../utils";

const ModulesCarousel = () => {
  const [count, setCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [type, setType] = useState("insights");
  const [timer, setTimer] = useState<any>(null);
  const [linear, setLinear] = useState<any>(null);

  const items = [
    {
      name: "Admissão e Demissão Digital",
      icon: "IconUserPlus",
      component: "hiring",
      track:
        "people_acquisition_signupsales_benefitsexpenseclient_solutionlist_digitaladmission_clicked",
    },
    {
      name: "Treinamentos",
      icon: "IconSchool",
      component: "learning",
      track:
        "people_acquisition_signupsales_benefitsexpenseclient_solutionlist_training_clicked",
    },
    {
      name: "Engajamento",
      icon: "IconSpeakerphone",
      component: "engagement",
      track:
        "people_acquisition_signupsales_benefitsexpenseclient_solutionlist_engagement_clicked",
    },
    {
      name: "Organograma",
      icon: "IconHierarchy2",
      component: "orgchart",
      track:
        "people_acquisition_signupsales_benefitsexpenseclient_solutionlist_orgchart_clicked",
    },
    {
      name: "People Analytics",
      icon: "IconChartPie",
      component: "peopleAnalytics",
      track:
        "people_acquisition_signupsales_benefitsexpenseclient_solutionlist_peopleanalytics_clicked",
    },
    {
      name: "Gestão personalizada",
      icon: "IconCheckbox",
      component: "employeesManagement",
      track:
        "people_acquisition_signupsales_benefitsexpenseclient_solutionlist_personalmanagement_clicked",
    },
    {
      name: "Gestão Eletrônica de Documentos",
      icon: "IconFolders",
      component: "ged",
      track:
        "people_acquisition_signupsales_benefitsexpenseclient_solutionlist_personalmanagement_clicked",
    },
  ];

  const handleClick = (position: any) => {
    segment({ track: items[position].track });
    setType(items[position].component);
    setCount(position);
    clearInterval(timer);
    clearInterval(linear);
    setProgress(100);
  };
  const SliderTab = ({
    name,
    icon,
    active,
    soon,
    onClick,
  }: {
    name: any;
    icon: any;
    active: any;
    soon?: any;
    onClick: any;
  }) => {
    return (
      <Grid item md={12} onClick={onClick}>
        <StyledSliderTab active={active}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Icons
              name={icon}
              fill="transparent"
              color="#F20D7A"
              style={{ marginRight: "12px" }}
            />
            <StyledText variant="body3" style={{ fontWeight: 700 }}>
              {name}
            </StyledText>
          </div>
          {soon && (
            <Tag
              variant="primary"
              style={{
                cursor: "auto",
                pointerEvents: "none",
                background: "#FFE0EF",
                marginRight: "10px",
                width: "100px",
                height: "24px",
              }}
            >
              Em breve!
            </Tag>
          )}
        </StyledSliderTab>
        {active ? (
          <LinearProgress variant="determinate" value={progress} />
        ) : (
          <></>
        )}
      </Grid>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => (prevCount + 1) % items.length);
    }, 10000);

    setTimer(interval);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const progresso = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 100);
    setLinear(progresso);
    return () => {
      clearInterval(progresso);
    };
  }, []);

  useEffect(() => {
    setType(items[count].component);
    setProgress(0);
  }, [count]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "132px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "25%" }}>
        <StyledTitle variant="headline7" style={{ marginBottom: "32px" }}>
          Confira tudo o que a solução de Gestão de Pessoas tem!
        </StyledTitle>
        <Grid container>
          {items.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <SliderTab
                  name={item.name}
                  icon={item.icon}
                  // soon={item?.soon}
                  active={count === index}
                  onClick={() => handleClick(index)}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      </div>
      <ModulesVideoPlayer type={type} />
    </div>
  );
};

export { ModulesCarousel };
